import React, { useEffect, useState } from 'react'
import DataNotFound from '../Components/TailwindComponent/DataNotFound';
import LoadingComp from '../Components/TailwindComponent/LoadingComp';
import PostCountComp from '../Components/TailwindComponent/PostCountComp';
import PostTab from '../Components/TailwindComponent/PostTab';

const PostCount = () => {
    const [tabType, setTabType] = useState("Today");
    const [loading, setLoading] = useState(false)
    const [postCount, setPostCount] = useState([]);
    useEffect(() => {
        PostsCountData();
      }, []);
      const PostsCountData = () => {
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          "Token 88927950e8858bb239c62d8dcb81292630996841"
        );
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          redirect: "follow",
        };
    
        fetch(
          "https://api.sasone.in/apiadmin/v1_1/GetOnepostPostsCountAdmin",
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            if (result.status_code === 200 && result.status === "Success") {
              setPostCount(result.data);
            }
          })
          .catch((error) => console.log("error", error))
          .finally(()=> setLoading(false))
      };
      const filterData = (type) => {
        if (type === "Today") {
          return postCount.CurrentDate;
        }
        if (type === "This Week") {
          return postCount.CurrentWeek;
        }
        if (type === "This Month") {
          return postCount.CurrentMonth;
        }
      };
      const tabs = [
        { name: "Today", current: true },
        { name: "This Week", current: false },
        { name: "This Month", current: false },
      ];
  return (
    <div className=" border border-slate-100 p-2 sm:p-6 mb-8 rounded">
        <div className=' font-bold text-lg'>Post Count</div>
        <PostTab setTabType={setTabType} tabs={tabs}  tabType={tabType} />
         { loading?<LoadingComp />: filterData(tabType)?<PostCountComp data={filterData(tabType)} />:<DataNotFound />}
  </div>
  )
}

export default PostCount