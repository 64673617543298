import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const List = (props) => {
  const navigate = useNavigate();
  const dateAndTimeHandler = (SignupDate) => {
    var date = new Date(SignupDate);
    return date.toString().slice(3, 25)
  }

  const dateHandler = (date) => {
    const dateObj = new Date(date);
    const day = dateObj.getDate();
    const monthNameLong = dateObj.toLocaleString("en-US", { month: "short" });
    const year = dateObj.getFullYear();
    const hour = dateObj.getHours();
    const month = dateObj.getMonth();
    return (day <= 9 ? "0" : "") + day + "-" + monthNameLong + "-" + year + "," + (hour <= 9 ? "0" : "") + hour + ":" + (month <= 9 ? "0" : "") + month;
  };
  const dateHandlerTwo = (date) => {
    const dateObj = new Date(date);
    const day = dateObj.getDate();
    const monthNameLong = dateObj.toLocaleString("en-US", { month: "short" });
    const year = dateObj.getFullYear();
    return (day <= 9 ? "0" : "") + day + "-" + monthNameLong + "-" + year;
  };
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const statusPayment = (payment) => {
    switch (payment) {
      case "1": {
        return (
          <div>Success</div>
        )
      }
      case "0": {
        return (
          <div>Cancel</div>
        )
      }
      case "Panding": {
        return (
          <div>Pending</div>
        )
      }
    }
  }


  const selectType = (type) => {
    switch (type) {
      case "Signup": {
        return (
          <table
            className="min-w-full border-separate overflow-x-hidden p-4"
            style={{ borderSpacing: 0 }}
          >
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                >
                  Name
                </th>
                {/* <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                >
                  Job Title
                </th> */}
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                >
                  Email
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Phone Number
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Date & Time
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Company Name
                </th>
                {/* <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Number of Employee
                </th> */}
                {/* <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Source
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Device
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Promo-code
                </th> */}
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Subscription Type
                </th>
                {/* <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Status
                </th> */}



                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                >
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>

            <tbody className="bg-white">
              {props.data.map((person, personIdx) => (
                <tr key={person.email}>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                    )}
                  >
                    {person.FirstName} {person.LastName}
                  </td>
                  {/* <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500 sm:table-cell"
                    )}
                  >
                    {person.JobTitle}
                  </td> */}
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500  lg:table-cell"
                    )}
                  >
                    {person.Email}
                  </td>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                    )}
                  >
                    {person.Mobile}
                  </td>

                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                    )}
                  >
                    {person.CreatedAt && dateHandler(person.CreatedAt)}
                  </td>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                    )}
                  >
                    {person.CompanyName}
                  </td>
                  {/* <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                    )}
                  >
                    {person.NumberOfEmployee}
                  </td> */}
                  {/* <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                    )}
                  >
                    {person.Source}
                  </td> */}
                  {/* <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                    )}
                  >
                    {person.Device}
                  </td> */}
                  {/* <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                    )}
                  >
                    {person.promocode}
                  </td> */}
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                    )}
                  >
                    {person.SubscriptionType}
                  </td>
                  {/* <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                    )}
                  >
                    {(person.SubscriptionType === "Trial" && person.status === "1") ? "Active" : (person.SubscriptionType === "Paid" && person.status === "1") ? "Active" :
                      (person.SubscriptionType === "" && person.status === "0") ? "" : (person.SubscriptionType === "Trial" && person.status === "0") ? "Expired" : (person.SubscriptionType === "Paid" && person.status === "0") ? "Expired" : "Expired"}

                  </td> */}


                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "relative whitespace-nowrap py-3 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-8"
                    )}
                  >
                    <a
                      href="edit"
                      className={person.UserId === "" || person.UserId === null ? "text-gray-400 hover:text-indigo-900 pointer-events-none cursor-not-allowed" : "text-indigo-600 hover:text-indigo-900"}
                    >
                      <Link
                        to={"/UserDetailInfo/" + person.UserId}
                        state={{ currentTab: "Profile" }}
                      // className={classes.userName}
                      >
                        View
                      </Link>

                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        );
      }
      case "Sales": {
        return (
          <table
            className="min-w-full border-separate overflow-x-hidden p-4"
            style={{ borderSpacing: 0 }}
          >
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                >
                  Sales Executive
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                >
                  Job Title
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                >
                  Email
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Phone Number
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Date & Time
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Company Name
                </th>


                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Promo-code
                </th>

                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Status
                </th>



                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Update executive
                </th>
              </tr>
            </thead>

            <tbody className="bg-white">
              {props.data.map((person, personIdx) => (
                <tr key={person.email}>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                    )}
                  >
                    {person.SalesExecutive}
                  </td>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                    )}
                  >
                    {person.FirstName} {person.LastName}
                  </td>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500 sm:table-cell"
                    )}
                  >
                    {person.JobTitle}
                  </td>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500  lg:table-cell"
                    )}
                  >
                    {person.Email}
                  </td>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                    )}
                  >
                    {person.Mobile}
                  </td>

                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                    )}
                  >
                    {person.CreatedAt && dateHandler(person.CreatedAt)}
                  </td>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                    )}
                  >
                    {person.CompanyName}
                  </td>

                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                    )}
                  >
                    {person.promocode}
                  </td>

                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                    )}
                  >
                    {person.subscription === "1" ? "Active" : "Expired"}

                  </td>


                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "cursor-pointer relative whitespace-nowrap py-3 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-8"
                    )}
                  >
                    <div
                      onClick={() => { props.modalOpen(person.UserId) }}
                      className={person.UserId === "" || person.UserId === null ? "cusror-pointer text-gray-400 hover:text-indigo-900 pointer-events-none cursor-not-allowed" : "cusror-pointer text-indigo-600 hover:text-indigo-900"}
                    >

                      Update


                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        );
      }
      case "Banner": {
        return (
          <table
            className="min-w-full border-separate overflow-x-hidden p-4"
            style={{ borderSpacing: 0 }}
          >
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                >
                  S No.
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                >
                  Desktop Image
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                >
                  Mobile Image
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                >
                  Banner Id
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Is Deleted
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Is Published
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Page Name
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Created Date
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Updated Date
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Delete
                </th>


              </tr>
            </thead>

            <tbody className="bg-white">
              {props.data.map((person, personIdx) => (
                <tr key={person.email}>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                    )}
                  >
                    {person.SNo}
                  </td>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                    )}
                  >
                    <img src={person.DesktopImageURL} className="h-12 w-12" />
                  </td>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500 sm:table-cell"
                    )}
                  >
                    <img src={person.MobileImage} className="h-12 w-12" />
                  </td>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500  lg:table-cell"
                    )}
                  >
                    {person.BannerUUID}
                  </td>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                    )}
                  >
                    {person.IsDeleted}
                  </td>

                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                    )}
                  >
                    {person.IsPublish}
                  </td>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                    )}
                  >
                    {person.PageName}
                  </td>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                    )}
                  >
                    {person.CreatedAt && dateHandler(person.CreatedAt)}
                  </td>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                    )}
                  >
                    {person.UpdatedAt && dateHandler(person.UpdatedAt)}
                  </td>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                    )}
                  >
                    <div
                      className="cursor-pointer text-indigo-600 hover:text-indigo-900"
                      onClick={() => { props.modalOpen(person.BannerUUID) }}
                    >

                      Delete
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        );
      }
      case "CustomerForm": {
        return (
          <>
            <table
              className="min-w-full border-separate p-4 overflow-scroll lg:overflow-hidden"
              style={{ borderSpacing: 0 }}
            >
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                  >
                    Full Name
                  </th>

                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                  >
                    Mobile
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                  >
                    FormType
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                  >
                    Product
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                  >
                    Message
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                  >
                    Created Date
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                  >
                    Updated Date
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {props.data.map((person, personIdx) => (
                  <tr key={person.email}>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {person.FullName || "-"}
                    </td>

                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500  lg:table-cell"
                      )}
                    >
                      {person.Email || "-"}
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500  lg:table-cell"
                      )}
                    >
                      {person.Mobile || "-"}
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500  lg:table-cell"
                      )}
                    >
                      {person.FormType || "-"}
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500  lg:table-cell"
                      )}
                    >
                      {person.Product || "-"}
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500  lg:table-cell"
                      )}
                    >
                      {person.Message || "-"}
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500  lg:table-cell"
                      )}
                    >
                      {person.CreatedAt && dateHandler(person.CreatedAt)}
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500  lg:table-cell"
                      )}
                    >
                      {person.UpdatedAt && dateHandler(person.UpdatedAt)}
                    </td>




                  </tr>
                ))}
              </tbody>
            </table>
          </>
        );
      }
      case "Q&A": {
        return (
          <>
            <table
              className="min-w-full border-separate p-4 overflow-scroll lg:overflow-hidden"
              style={{ borderSpacing: 0 }}
            >
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 sm:w-[30px] border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                  >
                    S.No.
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 sm:w-[35%] border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                  >
                    Question
                  </th>

                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b sm:w-[40%] border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                  >
                    Answer
                  </th>


                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b sm:w-[12%] border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                  >
                    Created Date
                  </th>

                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b  border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                  >
                    Status
                  </th>

                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b sm:w-[40%] border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                  >
                    EDIT
                  </th>

                </tr>
              </thead>
              <tbody className="bg-white">
                {props.data.map((person, personIdx) => (
                  <tr key={person.email}>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        " sm:w-[30px] py-3 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {personIdx + 1 || "-"}
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        " sm:w-[35%] py-3 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {person.Question || "-"}
                    </td>

                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        " px-3 w-[40%] py-3 text-sm text-gray-500  lg:table-cell"
                      )}
                    >
                      {person.Answer || "-"}
                    </td>




                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "w-[12%] px-3 py-3 text-sm text-gray-500  lg:table-cell"
                      )}
                    >
                      {person.CreatedAt && dateHandler(person.CreatedAt)}
                    </td>

                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "w-[5%] px-3 py-3 text-sm   lg:table-cell cursor-pointer text-gray-500"
                      )}
                    >
                      {person.IsLive === "1" ? "Live" : "Not Live"}
                    </td>

                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm   lg:table-cell cursor-pointer text-indigo-500"
                      )}
                      onClick={() => navigate("/updatequestion/" + person.QuestionUUID)}
                    >
                      Update
                    </td>


                  </tr>
                ))}
              </tbody>
            </table>
          </>
        );
      }
      case "Login": {
        return (
          <>
            <table
              className="min-w-full border-separate p-4 overflow-scroll lg:overflow-hidden"
              style={{ borderSpacing: 0 }}
            >
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                  >
                    Name
                  </th>

                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                  >
                    Last login Date & Time
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                  >
                    <span className="sr-only">Edit</span>
                  </th>

                </tr>
              </thead>
              <tbody className="bg-white">
                {props.data.map((person, personIdx) => (
                  <tr key={person.email}>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {person.FirstName} {person.LastName}
                    </td>

                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500  lg:table-cell"
                      )}
                    >
                      {person.Email}
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500  lg:table-cell"
                      )}
                    >
                      {person.UpdatedAt && dateHandler(person.UpdatedAt)}
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "relative whitespace-nowrap py-3 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-8"
                      )}
                    >
                      <a
                        href="edit"
                        className={person.UserId === "" || person.UserId === null ? "text-gray-400 hover:text-indigo-900 pointer-events-none cursor-not-allowed" : "text-indigo-600 hover:text-indigo-900"}
                      >
                        <Link
                          to={"/UserDetailInfo/" + person.UserId}
                          state={{ currentTab: "Profile" }}
                        // className={classes.userName}
                        >
                          View
                        </Link>

                      </a>
                    </td>



                  </tr>
                ))}
              </tbody>
            </table>
          </>
        );
      }
      case "FreeTrail": {
        return (
          <>
            <table
              className="min-w-full border-separate overflow-x-hidden p-4"
              style={{ borderSpacing: 0 }}
            >
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                  >
                    Name
                  </th>

                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                  >
                    Mobile
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                  >
                    Product
                  </th>

                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                  >
                    Start date & Time
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                  >
                    End date & Time
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                  >
                    Extend free trial
                  </th>


                  {/* <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                  >
                    <span className="sr-only">Edit</span>
                  </th> */}
                </tr>
              </thead>
              <tbody className="bg-white ">
                {props.data.map((person, personIdx) => (
                  <tr key={person.Email}>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                      )}
                    >
                      <a
                        href="edit"
                        className={person.UserId === "" || person.UserId === null ? "text-gray-400 hover:text-indigo-900 pointer-events-none cursor-not-allowed" : "text-indigo-600 hover:text-indigo-900"}
                      >
                        <Link
                          to={"/UserDetailInfo/" + person.UserId}
                          state={{ currentTab: "Profile" }}
                        // className={classes.userName}
                        >
                          {person.FirstName} {person.LastName}
                        </Link>
                      </a>
                    </td>

                    {/* <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "relative whitespace-nowrap py-3 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-8"
                      )}
                    >
                      <a
                        href="edit"
                        className={person.UserId === "" || person.UserId === null ? "text-gray-400 hover:text-indigo-900 pointer-events-none cursor-not-allowed" : "text-indigo-600 hover:text-indigo-900"}
                      >
                        <Link
                          to={"/UserDetailInfo/" + person.UserId}
                          state={{ currentTab: "Profile" }}
                        // className={classes.userName}
                        >
                          View
                        </Link>

                      </a>
                    </td> */}

                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500  lg:table-cell"
                      )}
                    >
                      {person.Email}
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                      )}
                    >
                      {person.Mobile}
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                      )}
                    >
                      {person.ProductName}
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                      )}
                    >
                      {dateHandler(person.ValidFrom)}
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                      )}
                    >
                      {dateHandler(person.ValidTo)}
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                      )}
                    >
                      {person.status == 1 ? 'Active' : 'Expired'}
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                      )}
                    >
                      <div
                        className="cursor-pointer text-indigo-600 hover:text-indigo-900"
                        onClick={() => { props.modalOpen(person.ValidTo, person.SubscriptionId) }}
                      >

                        Extend free trial
                      </div>
                    </td>

                    {/* <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-8"
                      )}
                    >
                      <a
                        href="edit"
                        className="text-indigo-600 hover:text-indigo-900"
                      >
                        <Link
                          to={"/UserDetailInfo/" + person.UserId}

                        >
                         View
                        </Link>
                      </a>
                    </td> */}

                  </tr>
                ))}
              </tbody>
            </table>
          </>
        );
      }
      case "Subscription": {
        return (
          <>
            <table
              className="min-w-full border-separate overflow-x-hidden p-4"
              style={{ borderSpacing: 0 }}
            >
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                  >
                    Name
                  </th>

                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                  >
                    Mobile
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                  >
                    Email
                  </th>
                  <th

                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                  >
                    Start Date & Time
                  </th>
                  <th

                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                  >
                    End Date & Time
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                  >
                    Amount
                  </th>
                  {/* <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                  >
                    Product
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                  >
                    Status
                  </th> */}
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                  >
                    Extend Subscription
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                  >
                   Gold Credits Remaining
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                  >
                    Gold Credits Consumed
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                  >
                    Upgrade Status
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                  >
                    Delete
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                  >
                    <span className="sr-only">Edit</span>
                  </th>

                </tr>
              </thead>
              <tbody className="bg-white">
                {props.data.map((person, personIdx) => (
                  <tr key={person.email}>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                      )}
                    >
                      <a
                        href={`https://onepost.sasone.in?userToken=${person.UserToken}`}
                        target="_blank"
                        className={person.UserId === "" || person.UserId === null ? "text-gray-400 hover:text-indigo-900 pointer-events-none cursor-not-allowed" : "text-indigo-600 hover:text-indigo-900"}
                      >
                        <div
                          
                          state={{ currentTab: "Profile" }}
                         
                        >
                          {person.FirstName} {person.LastName}
                        </div>
                      </a>
                    </td>
                    {/* <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-4 text-sm text-gray-500  lg:table-cell"
                      )}
                    >
                     NA
                    </td> */}

                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500  lg:table-cell"
                      )}
                    >
                      {person.Mobile}
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500  lg:table-cell"
                      )}
                    >
                      {person.Email}
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                      )}
                    >
                      {person.ValidFrom && dateHandler(person.ValidFrom)}
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                      )}
                    >
                      {person.ValidTo && dateHandler(person.ValidTo)}
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                      )}
                    > {person.TotalAmount}
                    </td>
                    {/* <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-indigo-500 font-semibold"
                      )}
                    >  <a href={"https://onepost.sasone.in/" + person.UserToken} target={"_blank"}>{person.ProductName}</a>
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                      )}
                    > {person.status == 1 ? 'Active' : 'Expired'}
                    </td> */}
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                      )}
                    >
                      <div
                        className="cursor-pointer text-indigo-600 hover:text-indigo-900"
                        onClick={() => { props.modalOpen(person.ValidTo, person.SubscriptionId) }}
                      >

                        Extend Susbcription
                      </div>
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap text-center px-3 py-3 text-sm text-gray-500"
                      )}
                    > {person.TotalGCredits === null ? "0" : person.TotalGCredits} 
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap text-center px-3 py-3 text-sm text-gray-500"
                      )}
                    > {person.TotalConsumed}
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                      )}
                    > {person.IsUpgraded === "0" ? <div className="border border-red-600 p-1 text-black rounded-md">Not Upgraded</div> : <div className="text-white bg-green-600 items-center text-center p-1 rounded-md">Upgraded</div>}
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                      )}
                    >
                      <div
                        className="cursor-pointer text-red-500 hover:text-red-900"
                        onClick={() => { props.DeleteMod(person.SubscriptionId); console.log("woperfasdnkfasdfosdfjop") }}
                      >

                        Delete
                      </div>
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "relative whitespace-nowrap py-3 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-8"
                      )}
                    >
                      <a
                        href="edit"
                        className={person.UserId === "" || person.UserId === null ? "text-gray-400 hover:text-indigo-900 pointer-events-none" : "text-indigo-600 hover:text-indigo-900"}
                      >
                        <Link
                          to={"/UserDetailInfo/" + person.UserId}
                          state={{ currentTab: "Subscription" }}
                        // className={classes.userName}
                        >
                          View
                        </Link>

                      </a>
                    </td>

                  </tr>
                ))}
              </tbody>
            </table>

          </>
        );
      }
      case "PaymentAttempt": {
        return (
          <>
            <table
              className="min-w-full border-separate overflow-x-hidden p-4"
              style={{ borderSpacing: 0 }}
            >
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                  >
                    Title
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                  >
                    Phone Number
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                  >
                    Amount
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                  >
                    Product Name
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                  >
                    Product Code
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                  >
                    Service Type
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                  >
                    Payment Status
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                  >
                    Created Date
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                  >
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {props.data.map((person, personIdx) => (
                  <tr key={person.email}>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                      )}
                    >
                      <a
                        href="edit"
                        className={person.UserId === "" || person.UserId === null ? "text-gray-400 hover:text-indigo-900 pointer-events-none cursor-not-allowed" : "text-indigo-600 hover:text-indigo-900"}
                      >
                        <Link
                          to={"/UserDetailInfo/" + person.UserId}
                          state={{ currentTab: "Profile" }}
                        // className={classes.userName}
                        >
                          {person.FirstName} {person.LastName}
                        </Link>
                      </a>
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500 sm:table-cell"
                      )}
                    >
                      {person.JobTitle}
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500  lg:table-cell"
                      )}
                    >
                      {person.Email}
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                      )}
                    >
                      {person.Mobile}
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                      )}
                    >
                      {person.TotalAmount}
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                      )}
                    >
                      {person.ProductName}
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                      )}
                    >
                      {person.PricingCode}
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                      )}
                    >
                      {person.ServiceType}
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                      )}
                    >
                      {statusPayment(person.PaymentStatus)}
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                      )}
                    >
                      {person.CreatedAt && dateHandler(person.CreatedAt)}
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "relative whitespace-nowrap py-3 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-8"
                      )}
                    >
                      <div

                        className={person.UserId === "" || person.UserId === null ? "text-gray-400 hover:text-indigo-900 pointer-events-none" : "text-indigo-600 hover:text-indigo-900"}
                      >
                        <Link
                          to={"/UserDetailInfo/" + person.UserId}
                          state={{ currentTab: "Payment Attempt" }}
                          className={person.UserId === "" || person.UserId === null ? "text-gray-400 hover:text-indigo-900 pointer-events-none" : "text-indigo-600 hover:text-indigo-900"}
                        >
                          View
                        </Link>

                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

          </>
        );
      }
      case "Career": {
        return (
          <table
            className="min-w-full border-separate overflow-x-hidden p-4"
            style={{ borderSpacing: 0 }}
          >
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                >
                  Position you are applying for
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                >
                  Full Name
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                >
                  Email
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Phone Number
                </th>
                {/* <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Date Of Birth
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Current city
                </th> */}
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Candidate Resume
                </th>
                {/* <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Candidate Photo
                </th> */}
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  LinkedIn Profile
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Created date
                </th>
                {/* <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Updated date
                </th> */}

              </tr>
            </thead>

            <tbody className="bg-white">
              {props.data.map((person, personIdx) => (
                <tr key={person.email}>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                    )}
                  >
                    {person.ApplyingFor}
                  </td>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                    )}
                  >
                    {person.FirstName} {person.LastName}
                  </td>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500 sm:table-cell"
                    )}
                  >
                    {person.Email}
                  </td>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500  lg:table-cell"
                    )}
                  >
                    {person.Mobile}
                  </td>
                  {/* <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                    )}
                  >
                    {person.DOB}
                  </td>

                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                    )}
                  >
                    {person.CurrentCity}
                  </td> */}
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                    )}
                  >
                    <a href={person.CandidateResume} target={"_blank"} download title={person.CandidateResume} className="underline text-indigo-600">Resume download</a>
                  </td>
                  {/* <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                    )}
                  >
                    <img src={person.CandidatePhoto} className="w-8 h-8" title={person.FirstName + " " + person.LastName} />
                  </td> */}
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                    )}
                  >
                    {person.LinkedInProfile}
                  </td>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                    )}
                  >
                    {person.CreatedAt && dateHandler(person.CreatedAt)}
                  </td>
                  {/* <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                    )}
                  >
                    {person.UpdatedAt && dateHandler(person.UpdatedAt)}
                  </td> */}





                </tr>
              ))}
            </tbody>
          </table>
        );
      }
      case "Requestademo": {
        return (
          <table
            className="min-w-full border-separate overflow-x-hidden p-4"
            style={{ borderSpacing: 0 }}
          >
            <thead className="bg-gray-50">
              <tr>

                <th
                  scope="col"
                  className="sticky pl-4 top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                >
                  Email
                </th>

                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Created date
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Updated date
                </th>

              </tr>
            </thead>

            <tbody className="bg-white">
              {props.data.map((person, personIdx) => (
                <tr key={person.email}>

                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap pl-4 px-3 py-3 text-sm text-gray-500 sm:table-cell"
                    )}
                  >
                    {person.BuisnessEmail}
                  </td>

                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                    )}
                  >
                    {person.CreatedAt && dateHandler(person.CreatedAt)}
                  </td>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                    )}
                  >
                    {person.UpdatedAt && dateHandler(person.UpdatedAt)}
                  </td>

                </tr>
              ))}
            </tbody>
          </table>
        );
      }
      case "Enquiry": {
        return (
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="mt-3 flex flex-col">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr className="divide-x divide-gray-200">
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            Name
                          </th>

                          <th
                            scope="col"
                            className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Bussiness Email
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-6"
                          >
                            Phone Number
                          </th>

                          <th
                            scope="col"
                            className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                          >
                            Company Name
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Company Website
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Company size
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Product
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Country
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Date & Time
                          </th>
                        </tr>
                      </thead>

                      <tbody className="divide-y divide-gray-200 bg-white">
                        {props.data.map((person) => (
                          <tr
                            key={person.email}
                            className="divide-x divide-gray-200"
                          >
                            <td className="whitespace-nowrap py-3 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-6">
                              {person.FirstName} {person.LastName}
                            </td>


                            <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                              {person.BuisnessEmail}
                            </td>
                            <td className="whitespace-nowrap py-3 pl-4 pr-4 text-sm text-gray-500 sm:pr-6">
                              {person.PhoneNumber}
                            </td>


                            <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                              {person.CompanyName}
                            </td>
                            <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                              {person.CompanyWebsite}
                            </td>
                            <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                              {person.CompanySize}
                            </td>
                            <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                              {person.ChooseProduct}
                            </td>
                            <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                              {person.Country}
                            </td>
                            <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                              {person.CreatedAt && dateHandler(person.CreatedAt)}
                            </td>



                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
      case "MostActiveUsers": {
        return (
          <table
            className="min-w-full border-separate overflow-x-hidden p-4"
            style={{ borderSpacing: 0 }}
          >
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                >
                  Email
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                >
                  Mobile
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Post Count
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Last Login
                </th>

              </tr>
            </thead>

            <tbody className="bg-white">
              {props.data?.map((person, personIdx) => (
                <tr key={person.email}>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                    )}
                  >
                    <a href={"https://onepost.sasone.in/" + person.UserToken} target="_blank" className="hover:underline"> {person.FirstName} {person.LastName}</a>
                  </td>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap py-3 pl-2 pr-3 text-sm font-medium text-gray-900 "
                    )}
                  >
                    {person.Email}
                  </td>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap py-3 pl-2 pr-3 text-sm font-medium text-gray-900 "
                    )}
                  >
                    {person.Mobile}
                  </td>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap py-3 pl-2 pr-3 text-sm font-medium text-gray-900 "
                    )}
                  >
                    {person.PostCount}
                  </td>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap py-3 pl-2 pr-3 text-sm font-medium text-gray-900 "
                    )}
                  >
                    {person.LastLoginDate ? dateHandlerTwo(person.LastLoginDate) : ""}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        );
      }
      case "MostLoginUsers": {
        return (
          <table
            className="min-w-full border-separate overflow-x-hidden p-4"
            style={{ borderSpacing: 0 }}
          >
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                >
                  Email
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                >
                  Mobile
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Login Count
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Last Login
                </th>
              </tr>
            </thead>

            <tbody className="bg-white">
              {props.data?.map((person, personIdx) => (
                <tr key={person.email}>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                    )}
                  >
                    <a href={"https://onepost.sasone.in/" + person.UserToken} target="_blank" className="hover:underline"> {person.FirstName} {person.LastName}</a>
                  </td>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap py-3 pl-2 pr-3 text-sm font-medium text-gray-900 "
                    )}
                  >
                    {person.Email}
                  </td>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap py-3 pl-2 pr-3 text-sm font-medium text-gray-900 "
                    )}
                  >
                    {person.Mobile}
                  </td>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap py-3 pl-2 pr-3 text-sm font-medium text-gray-900 "
                    )}
                  >
                    {person.LoginCount}
                  </td>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap py-3 pl-2 pr-3 text-sm font-medium text-gray-900 "
                    )}
                  >
                    {person.LastLoginDate ? dateHandlerTwo(person.LastLoginDate) : ""}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        );
      }
      case "ArticleTable": {
        return (
          <table
            className="min-w-full border-separate overflow-x-hidden p-4"
            style={{ borderSpacing: 0 }}
          >
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-5 py-3.5 text-center text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Sl. No.
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-center text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  ID
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-center text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-center text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Action
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                >
                  Cover Image
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-16 py-3.5 text-center text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                >
                  Title
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-20 py-3.5 text-center text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Short Description
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                >
                  Mobile Thumbnail
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-center text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Keywords
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-center text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Hashtags
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-center text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Category
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-center text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Type
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-center text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Sub Category
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-center text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Article Source
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-center text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Author
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-center text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Created Date & Time
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-center text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Updated Date & Time
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-center text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Static
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-center text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Article URL
                </th>
              </tr>
            </thead>

            <tbody className="bg-white">
              {
                props.data.map((article, articleIdx) =>
                (
                  <tr key={articleIdx}>
                    <td
                      className={classNames(
                        articleIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap text-center px-5 py-3 text-sm text-gray-500"
                      )}
                    >
                      {1 + 10 * (props.pageNum - 1) + articleIdx}
                    </td>
                    <td
                      className={classNames(
                        articleIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap text-center px-3 py-3 text-sm text-gray-500"
                      )}
                    >
                      {article.ID}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500 border-b border-gray-200">
                      <span
                        className={
                          article.IsPublished == 1
                            ? "inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800"
                            : "inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800"
                        }
                      >
                        {article.IsPublished == 1
                          ? "Live"
                          : "Not Live"}
                      </span>
                    </td>
                    <td
                      className={classNames(
                        articleIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "relative whitespace-nowrap py-3 pr-4 pl-3 text-center text-sm font-medium sm:pr-6 lg:pr-8"
                      )}
                    >
                      <div className="flex items-center space-x-4 text-blue-600 hover:text-blue-500">
                        <div>
                          <Link className="text-indigo-600 hover:text-indigo-900"
                            to={"/UpdateArticle/" + article.ArticleUUID}
                            onClick={() => props.setLoading(true)}
                          >
                            <div className="flex flex-col cursor-pointer font-medium">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                />
                              </svg>

                              <div>Edit</div>
                            </div>
                          </Link>
                        </div>
                        <div
                          className="text-red-600 hover:text-red-500"
                          onClick={() => {
                            props.setArticleUUID(article.ArticleUUID);
                            props.setOpen(true);
                          }}>
                          <div className="flex flex-col cursor-pointer  font-medium">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="#50577A"
                              className="h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                              />
                            </svg>

                            <div className=" text-gray-700 hover:text-gray-800">
                              Delete
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-center text-sm sm:pl-6 border-b border-gray-200">
                      <div className="flex items-center">
                        <div className="h-10 w-10 flex-shrink-0">
                          <img
                            className="h-10 w-10 rounded-full"
                            src={article.Image}
                            alt="articleImage"
                          />
                        </div>
                      </div>
                    </td>
                    <td
                      className={classNames(
                        articleIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap text-center px-3 py-3 text-sm text-gray-500 sm:table-cell truncate"
                      )}
                    >
                      <div className="truncate w-[200px]" dangerouslySetInnerHTML={{ __html: article.Title }} />
                    </td>
                    <td
                      className={classNames(
                        articleIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap text-center px-3 py-3 text-sm text-gray-500  lg:table-cell truncate"
                      )}
                    >
                      <div className="truncate w-[200px]" dangerouslySetInnerHTML={{ __html: article.ShortDescription }} />
                    </td>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-center text-sm sm:pl-6 border-b border-gray-200">
                      <div className="flex items-center">
                        <div className="h-10 w-10 flex-shrink-0">
                          <img
                            className="h-10 w-10 rounded-full"
                            src={article.MobileThumb}
                            alt="mobileThumbnail"
                          />
                        </div>
                      </div>
                    </td>
                    <td
                      className={classNames(
                        articleIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap text-center px-3 py-3 text-sm text-gray-500"
                      )}
                    >
                      <div className="truncate w-[200px]">
                        {article.Keywords}
                      </div>
                    </td>
                    <td
                      className={classNames(
                        articleIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap text-center px-3 py-3 text-sm text-gray-500"
                      )}
                    >
                      <div className="truncate w-[200px]">
                        {article.Tags}
                      </div>
                    </td>
                    <td
                      className={classNames(
                        articleIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap text-center px-3 py-3 text-sm text-gray-500"
                      )}
                    >
                      <div className="truncate w-[150px]">
                        {article.Categories}
                      </div>
                    </td>
                    <td
                      className={classNames(
                        articleIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap text-center px-3 py-3 text-sm text-gray-500"
                      )}
                    >
                      <div className="truncate w-[150px]">
                        {article.Type}
                      </div>
                    </td>
                    <td
                      className={classNames(
                        articleIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap text-center px-3 py-3 text-sm text-gray-500"
                      )}
                    >
                      <div className="truncate w-[150px]">
                        {article.SubCategory}
                      </div>
                    </td>
                    <td
                      className={classNames(
                        articleIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap text-center px-3 py-3 text-sm text-gray-500"
                      )}
                    >
                      <div className="truncate w-[150px]">
                        {article.ArticleSource}
                      </div>
                    </td>
                    <td
                      className={classNames(
                        articleIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap text-center px-3 py-3 text-sm text-gray-500"
                      )}
                    >
                      <div className="truncate w-[150px]">
                        {article.Author}
                      </div>
                    </td>
                    <td
                      className={classNames(
                        articleIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap text-center px-3 py-3 text-sm text-gray-500"
                      )}
                    >
                      {article.CreatedAt && dateHandler(article.CreatedAt)}
                    </td>
                    <td
                      className={classNames(
                        articleIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap text-center px-3 py-3 text-sm text-gray-500"
                      )}
                    >
                      {article.UpdatedAt && dateHandler(article.UpdatedAt)}
                    </td>
                    <td
                      className={classNames(
                        articleIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap text-center px-3 py-3 text-sm text-gray-500"
                      )}
                    >
                      {article.Static == 1 ? "Static" : "Not Static"}
                    </td>
                    <td
                      className={classNames(
                        articleIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "relative whitespace-nowrap py-3 pr-3 pl-3 text-center text-sm font-medium sm:pr-6 lg:pr-8"
                      )}
                    >
                      <div
                        className="text-indigo-600 text-center hover:text-indigo-500"
                        onClick={() => {
                          navigator.clipboard.writeText("https://www.sasone.in/articles/" + article.Title.replace(/[^a-zA-Z0-9 ]/g, " ")
                            .split(" ")
                            .join("-")
                            .split(" ")
                            .join("-")
                            .split("--")
                            .join("-")
                            .split("---")
                            .join("-")
                            .toLowerCase() + "/" + article.ArticleUUID)
                          props.setMessageOpen(true);
                          props.setAlertMessage("Article URL Copied");
                          props.messageCloseFunc();
                        }}
                      >
                        <div className="flex flex-col cursor-pointer pl-3 font-medium">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z" />
                          </svg>
                        </div>
                        <div className="cursor-pointer">
                          Copy URL
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )
      }

      case "TotalSasOneData": {
        return (
          <table
            className="min-w-full border-separate overflow-x-hidden p-4 overflow-y-scroll"
            style={{ borderSpacing: 0 }}>
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                >
                  Email
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Phone Number
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Date & Time
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Source
                </th>
              </tr>
            </thead>

            <tbody className="bg-white">
              {props.data.map((person, personIdx) => (
                <tr key={personIdx}>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                    )}
                  >
                    {person.Name}
                  </td>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500 sm:table-cell"
                    )}
                  >
                    {person.Email}
                  </td>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500  lg:table-cell"
                    )}
                  >
                    {person.Mobile}
                  </td>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                    )}
                  >
                    {person.DateTime && dateHandler(person.DateTime)}
                  </td>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                    )}
                  >
                    {person.Source}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )
      }
      case "PaymentDetailList": {
        return (
          <table
            className="min-w-full border-separate overflow-x-hidden p-4"
            style={{ borderSpacing: 0 }}
          >
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                >
                  Pricing Master ID
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                >
                  Pricing Code
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                >
                  Product Name
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                >
                  Price
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  SubTotal
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Date & Time
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  GST
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Grand Total
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Validity
                </th>
              </tr>
            </thead>

            <tbody className="bg-white">
              {props.data.map((person, personIdx) => (
                <tr key={person.email}>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                    )}
                  >
                    {person.PricingMasterID}
                  </td>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                    )}
                  >
                    {person.PricingCode}
                  </td>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500 sm:table-cell"
                    )}
                  >
                    {person.ProductName}
                  </td>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500  lg:table-cell"
                    )}
                  >
                    {person.Price}
                  </td>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                    )}
                  >
                    {person.SubTotal}
                  </td>

                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                    )}
                  >
                    {person.CreatedAt && dateHandler(person.CreatedAt)}
                  </td>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                    )}
                  >
                    {person.GST}
                  </td>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                    )}
                  >
                    {person.GrandTotal}
                  </td>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500"
                    )}
                  >
                    {person.Validity}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        );
      }
      case "Faq": {
        return (
          <table
            className="min-w-full border-separate overflow-x-hidden p-4"
            style={{ borderSpacing: 0 }}
          >
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 z-10  border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                >
                  Id
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-center text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                >
                  Question
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-center text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Answer
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-center text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-center text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Email
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-center text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Date
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-center text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Status
                </th>


                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Edit
                </th>

                <th
                  scope="col"
                  className="sticky top-0 z-10   border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-center  text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                >
                  Delete
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {props.data.map((person, personIdx) => (
                <tr key={person.email}>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap py-3 text-center pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                    )}
                  >
                    {person.ID}
                  </td>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap  text-center truncate px-3 py-3 text-sm text-gray-500 sm:table-cell"
                    )}
                  >
                    {person.Question}
                  </td>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-pre-wrap   break-words  text-center truncate px-3 py-3 text-sm text-gray-500 sm:table-cell"
                    )}
                  >
                    {person.Answer}
                  </td>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 text-center py-3 text-sm text-gray-500  lg:table-cell"
                    )}
                  >
                    {person.Name}
                  </td>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 text-center py-3 text-sm text-gray-500"
                    )}
                  >
                    {person.Email}
                  </td>

                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 text-center py-3 text-sm text-gray-500"
                    )}
                  >
                    {person.CreatedAt && dateHandler(person.CreatedAt)}
                  </td>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-center text-sm text-indigo-600"
                    )}
                  >

                    {person.Status === "1" ? "Live" : "Not Live"}


                  </td>

                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-center text-sm text-gray-500"
                    )}
                  >
                    <Link
                      to={"/UpdateFaq/" + person.ForumQuestionUUID + "?addfaq=addfaq"}
                      className="flex flex-col cursor-pointer font-medium">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                        />
                      </svg>

                      <div>Edit</div>
                    </Link>
                  </td>
                  <td
                    className={classNames(
                      personIdx !== props.data.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-center text-sm text-black"
                    )}
                  >
                    <button
                      onClick={() => props.modalOpen(person.ForumQuestionUUID)}
                      className="inline-block shrink-0 rounded-md border border-red-600   px-4 hover:bg-red-400 hover:text-white py-2 text-sm font-medium text-black transition   ">
                      Delete
                    </button>

                  </td>

                </tr>
              ))}
            </tbody>
          </table>
        );
      }
      case "CreativeDelivery": {
        return (
          <>
            <table
              className="min-w-full border-separate p-4 overflow-scroll lg:overflow-hidden"
              style={{ borderSpacing: 0 }}
            >
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                  >
                    Email
                  </th>

                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                  >
                    Creative Description
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                  >
                    Image
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                  >
                    RequestDate
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                  >
                    Delivery Date
                  </th>
                  {/* <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                  >
                  Update
                  </th> */}
                </tr>
              </thead>
              <tbody className="bg-white">
                {props.data.map((person, personIdx) => (
                  <tr key={person.email}>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {person.UserEmail}
                    </td>

                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500  lg:table-cell"
                      )}
                    >
                      {person.CreativeDescription}
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500  lg:table-cell"
                      )}
                    >
                      <img src={person.CreativeImageURL} className="h-8 w-8" />
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500  lg:table-cell"
                      )}
                    >
                      {person.RequestDate && dateHandler(person.RequestDate)}
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500  lg:table-cell"
                      )}
                    >
                      {person.DeliveryDate && dateHandler(person.DeliveryDate)}
                    </td>
                    {/* <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "relative whitespace-nowrap py-3 pr-4 pl-3 text-sm font-medium sm:pr-6 lg:pr-8"
                      )}
                    >
                      <a
                        href="edit"
                        className={person.UserId === "" || person.UserId === null ? "text-gray-400 hover:text-indigo-900 pointer-events-none cursor-not-allowed" : "text-indigo-600 hover:text-indigo-900"}
                      >
                        <Link
                          to={"/UserDetailInfo/" + person.UserId}
                          state={{ currentTab: "Profile" }}
                        
                        >
                     Update
                        </Link>

                      </a>
                    </td> */}



                  </tr>
                ))}
              </tbody>
            </table>
          </>
        );
      }
      case "CreativeRequest": {
        return (
          <>
            <table
              className="min-w-full border-separate p-4 overflow-scroll lg:overflow-hidden"
              style={{ borderSpacing: 0 }}
            >
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                  >
                    Email
                  </th>

                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                  >
                    Creative Request Description
                  </th>


                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                  >
                    Request Date
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                  >
                    Deliver Creative
                  </th>
                  {/* <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                  >
                  Update
                  </th> */}
                </tr>
              </thead>
              <tbody className="bg-white">
                {props.data.map((person, personIdx) => (
                  <tr key={person.email}>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {person.UserEmail}
                    </td>

                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500  lg:table-cell"
                      )}
                    >
                      {person.CreativeDescription}
                    </td>

                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500  lg:table-cell"
                      )}
                    >
                      {person.RequestDate && dateHandler(person.RequestDate)}
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "relative whitespace-nowrap py-3 pr-4 pl-3 text-sm font-medium sm:pr-6 lg:pr-8"
                      )}
                    >
                      <a
                        href="edit"
                        className={person.UserId === "" || person.UserId === null ? "text-gray-400 hover:text-indigo-900 pointer-events-none" : "text-indigo-600 hover:text-indigo-900"}
                      >
                        <Link
                          to={"/creativedeliveryform/" + person.CreativeUUID}
                          state={{ currentTab: "Subscription" }}
                        // className={classes.userName}
                        >
                          Creative Delivery Form
                        </Link>

                      </a>
                    </td>




                  </tr>
                ))}
              </tbody>
            </table>
          </>
        );
      }
      // #support_ticket
      case "support_ticket": {
        return (
          <>
            <table
              className="min-w-full border-separate p-4 overflow-scroll lg:overflow-hidden"
              style={{ borderSpacing: 0 }}
            >
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                  >
                    ID
                  </th>

                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                  >
                    FullName
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                  >
                    Image
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    className="sticky  top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                  >
                    Message
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                  >
                    Phone
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                  >
                    CompanyName
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                  >
                    Product
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                  >
                    CreatedAt
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                  >
                    UpdatedAt
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {props.data.map((person, personIdx) => (
                  <tr key={personIdx}>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {person.ID}
                    </td>

                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500  lg:table-cell"
                      )}
                    >
                      {`${person.FirstName} ${person.LastName}`}
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200 underline underline-offset-1 "
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500  lg:table-cell"
                      )}
                    >
                      {person.Images && (
                        <a href={person.Images} target="_blank" className="text-indigo-800 after:content-['_↗']">
                          Image
                        </a>
                      )}
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500  lg:table-cell "
                      )}
                    >
                      {person.Email}
                    </td>


                    <td
                      onClick={() => props.callBack(person.Message)}
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-pre-wrap   break-words  px-3 py-3 cursor-pointer text-sm  lg:table-cell text-gray-500 "
                      )}
                    >
                      {person.Message.slice(0, 60)}
                    </td>


                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap  px-3 py-3 text-sm text-gray-500  lg:table-cell"
                      )}
                    >
                      {person.Phone}
                    </td>

                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500  lg:table-cell"
                      )}
                    >
                      {person.CompanyName}
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500  lg:table-cell"
                      )}
                    >
                      {person.Product}
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500  lg:table-cell"
                      )}
                    >
                      {person.CreatedAt && dateHandler(person.CreatedAt)}
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500  lg:table-cell"
                      )}
                    >
                      {person.UpdatedAt && dateHandler(person.UpdatedAt)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        );
      }
      // #req_a_feature
      case "req_a_feature": {
        return (
          <>
            <table
              className="min-w-full border-separate p-4 overflow-scroll lg:overflow-hidden"
              style={{ borderSpacing: 0 }}
            >
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                  >
                    ID
                  </th>

                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                  >
                    RequestType
                  </th>

                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                  >
                    UserEmail
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                  >
                    ImageUrl
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                  >
                    Message
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                  >
                    BusinessType
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                  >
                    CompanySize
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                  >
                    CreatedAt
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                  >
                    UpdatedAt
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {props.data.map((person, personIdx) => (
                  <tr key={personIdx}>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {person.ID}
                    </td>

                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500  lg:table-cell"
                      )}
                    >
                      {person.RequestType}
                    </td>

                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500  lg:table-cell"
                      )}
                    >
                      {person.UserEmail}
                    </td>

                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200 underline underline-offset-1 "
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500  lg:table-cell"
                      )}
                    >
                      {person.ImageUrl && (
                        <a href={person.ImageUrl} target="_blank" className="text-indigo-800 after:content-['_↗']">
                          Image
                        </a>
                      )}
                    </td>
                    <td
                      onClick={() => props.callBack(person.FeatureDescription)}
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-pre-wrap   break-words  px-3 py-3 cursor-pointer text-sm  lg:table-cell text-gray-500 "
                      )}
                    >
                      {person.FeatureDescription.slice(0, 60)}
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500  lg:table-cell"
                      )}
                    >
                      {person.BusinessType}
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500  lg:table-cell"
                      )}
                    >
                      {person.CompanySize}
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500  lg:table-cell"
                      )}
                    >
                      {person.CreatedAt && dateHandler(person.CreatedAt)}
                    </td>
                    <td
                      className={classNames(
                        personIdx !== props.data.length
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-3 text-sm text-gray-500  lg:table-cell"
                      )}
                    >
                      {person.UpdatedAt && dateHandler(person.UpdatedAt)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        );
      }
      default:
        console.log("wrong input");
    }
  };
  return (
    <>
      <div className="  ">
        <div className="mt-8 flex flex-col overflow-scroll md:min-w-xl">
          <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                <div className="w-100 ">{selectType(props.type)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default List;