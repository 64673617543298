import React from "react";
import { useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../Components/TailwindComponent/Button";
import Input from "../Components/TailwindComponent/Input";
import { IoIosCamera } from "react-icons/io";
import countries from "../JsonFiles/countries.json";
import states from "../JsonFiles/states.json";
import Header from '../Components/TailwindComponent/Header';
import SuccessMessage from "../Components/TailwindComponent/SuccessMessage";



const UserProfileEdit = () => {
  const [profileImage, setProfileImage] = useState("");
  const [showProfileImage, setShowProfileImage] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userName, setUserName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [source, setSource] = useState("");
  const [device, setDevice] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [state, setState] = useState("");
  const [address, setAddress] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyWebsite, setcompanyWebsite] = useState("");
  const [companyPhone, setCompanyPhone] = useState("");
  const [companyEmail, setcompanyEmail] = useState("");
  const [noOfEmployee, setNoOfEmployee] = useState("");
  const [companyFax, setCompanyFax] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyCity, setCompanyCity] = useState("");
  const [companyState, setCompanyState] = useState("");
  const [companyCountry, setCompanyCountry] = useState("");
  const [companyPinCode, setCompanyPinCode] = useState("");
  const [employee, setEmployee] = useState("");
  const [show, setShow] = useState(false);
  const [apiFailled, setApiFailled] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  // useMemo(() => {
  //   setTimeout(() => {
      
  //     navigate("/userprofile")
  //   }, 5000);
  // }, [show]);


  const [error, setError] = useState({});
  let navigate = useNavigate();

  const onlyNumericMobile = (e) => {
    var reg = /^[0-9\b]+$/;
    reg.test(e.target.value) || e.target.value === ""
      ? setMobile(e.target.value)
      : console.log("");
  };
  const onlyNumericCompanyMobile = (e) => {
    var reg = /^[0-9\b]+$/;
    reg.test(e.target.value) || e.target.value === ""
      ?setCompanyPhone(e.target.value)
      : console.log("");
  };

  const profile = {
    userInfo: {
      "First Name": (
        <Input
          type={"BlockText"}
          label={"First Name"}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      ),
      "Last Name": (
        <Input
          type={"BlockText"}
          label={"Last Name"}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      ),
      Phone: (
        <Input
          type={"BlockText"}
          label={"Mobile"}
          value={mobile}
          maxLength={10}
          onChange={(e) => onlyNumericMobile(e) }
        />
      ),
      Email: (
        <Input
          type={"BlockText"}
          label={"Email"}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      ),
      Title: (
        <Input
          type={"BlockText"}
          label={"Title"}
          value={jobTitle}
          onChange={(e) => setJobTitle(e.target.value)}
        />
      ),
      Address: (
        <Input
          type={"BlockText"}
          label={"Address"}
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
      ),
      Country: (
        <Input
          label={"Select Country"}
          type={"DropdownLoc"}
          // placeHolder={"Select Country"}
          displayData={countries}
          onInput={() => {
            // error.country = " ";
          }}
          onChange={(e) => setCountry(e.target.value)}
          value={country}
          defaultCountry={country}
        />
      ),
      State: (
        <Input
          label={"Select State"}
          type={"DropdownLoc"}
          placeHolder={"Select State"}
          displayData={states}
          selectedCountry={country}
          onInput={() => {
            //  error.state = " ";
          }}
          onChange={(e) => {
            setState(e.target.value);
          }}
          value={state}
          defaultState={state}
        />
      ),
      /*  City: (
        <Input
          label={"Select City"}
          type={"DropdownLoc"}
          placeHolder={""}
          displayData={states}
          selectedCountry={country}
          onInput={() => {
            //  error.state = " ";
          }}
          onChange={(e) => {
            setCity(e.target.value);
          }}
          value={state}
          defaultState={state}
        />
      ), */

      Pincode: (
        <Input
          type={"BlockText"}
          label={"Pincode"}
          value={pinCode==="null"?"":pinCode}
          maxLength={6}
          onChange={(e) => setPinCode(e.target.value)}
        />
      ),
      Source: (
        <Input
          type={"BlockText"}
          label={"Source"}
          value={source}
          onChange={(e) => setSource(e.target.value)}
        />
      ),
      Device: (
        <Input
          type={"BlockText"}
          label={"Device"}
          value={device}
          onChange={(e) => setDevice(e.target.value)}
        />
      ),
      PromoCode: (
        <Input
          type={"BlockText"}
          label={"Promo Code"}
          value={promoCode}
          onChange={(e) => setPromoCode(e.target.value)}
        />
      ),
    },
    companyInfo: {
      "Company Name": (
        <Input
          type={"BlockText"}
          label={"Company Name"}
          value={companyName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      ),
      "Company Email": (
        <Input
          type={"BlockText"}
          label={"Company Email"}
          value={companyEmail}
          onChange={(e) => setcompanyEmail(e.target.value)}
        />
      ),
      "Company Phone": (
        <Input
          type={"BlockText"}
          label={"Company Phone"}
          value={companyPhone}
          maxLength={10}
          onChange={(e) => onlyNumericCompanyMobile(e)}
        />
      ),
      "Company Website": (
        <Input
          type={"BlockText"}
          label={"Company Website"}
          value={companyWebsite}
          onChange={(e) => setcompanyWebsite(e.target.value)}
        />
      ),
      "Number of Employees": (
        <Input
          type={"BlockText"}
          label={"Number of Employees"}
          value={noOfEmployee}
          onChange={(e) => setNoOfEmployee(e.target.value)}
        />
      ),
      "Company Address": (
        <Input
          type={"BlockText"}
          label={"Company Address"}
          value={companyAddress}
          onChange={(e) => setCompanyAddress(e.target.value)}
        />
      ),
      "Company Country": (
        <Input
          label={"Select Country"}
          type={"DropdownLoc"}
          // placeHolder={"Select Country"}
          displayData={countries}
          onInput={() => {
            // error.country = " ";
          }}
          onChange={(e) => setCompanyCountry(e.target.value)}
          value={companyCountry}
          defaultCountry={companyCountry}
        />
      ),
      "Company State": (
        <Input
          label={"Select State"}
          type={"DropdownLoc"}
          placeHolder={"Select State"}
          displayData={states}
          selectedCountry={companyCountry}
          onInput={() => {
            //  error.state = " ";
          }}
          onChange={(e) => {
            setCompanyState(e.target.value);
          }}
          value={companyState}
          defaultState={companyState}
        />
      ),
      /*  "Company City": (
         <Input
           type={"BlockText"}
           label={"Company City"}
           value={companyCity}
           onChange={(e) => setCompanyCity(e.target.value)}
         />
       ), */

      "Company Pincode": (
        <Input
          type={"BlockText"}
          label={"Company Pincode"}
          value={companyPinCode}
          maxLength={6}
          onChange={(e) => setCompanyPinCode(e.target.value)}
        />
      ),
    },
  };

  let url = window.location.href;
  let object = new URL(url);
  let path = object.pathname;
  let RequirementID = path.split("/").pop();
  console.log("userid", RequirementID);
  const { profileSection } = useParams();
  const { companySection } = useParams();
  console.log(companySection);
  console.log(showProfileImage, "aaaa");

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 88927950e8858bb239c62d8dcb81292630996841"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserId: RequirementID,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(global.apiLink + "/apiadmin/v1/UserFullDeatil", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          setFirstName(
            result.signupdatauser.FirstName
              ? result.signupdatauser.FirstName
              : ""
          );
          setLastName(
            result.signupdatauser.LastName ? result.signupdatauser.LastName : ""
          );
          setUserName(
            result.signupdatauser.UserName ? result.signupdatauser.UserName : ""
          );
          setShowProfileImage(
            result.signupdatauser.ProfilePicture
              ? result.signupdatauser.ProfilePicture
              : ""
          );
          setJobTitle(
            result.signupdatauser.JobTitle ? result.signupdatauser.JobTitle : ""
          );
          setMobile(
            result.signupdatauser.Mobile ? result.signupdatauser.Mobile : ""
          );
          setEmail(result.signupdatauser.Email);
          setSource(result.signupdatauser.Source);
          setDevice(result.signupdatauser.device);
          setPromoCode(result.signupdatauser.promocode);
          setCountry(
            result.signupdatauser.Country ? result.signupdatauser.Country : ""
          );
          setPinCode(result.signupdatauser.PinCode);
          setCity(result.signupdatauser.City);
          setAddress(
            result.signupdatauser.Address ? result.signupdatauser.Address : ""
          );
          setState(
            result.signupdatauser.State ? result.signupdatauser.State : ""
          );
          setCompanyId(result.signupdatauser.CompanyId
            ? result.signupdatauser.CompanyId
            : "");
          setCompanyName(
            result.signupdatauser.CompanyName
              ? result.signupdatauser.CompanyName
              : ""
          );
          setCompanyAddress(
            result.signupdatauser.CompanyAddress
              ? result.signupdatauser.CompanyAddress
              : ""
          );
          setCompanyCity(
            result.signupdatauser.CompanyCity
              ? result.signupdatauser.CompanyCity
              : ""
          );
          setCompanyState(
            result.signupdatauser.CompanyState
              ? result.signupdatauser.CompanyState
              : ""
          );
          setCompanyPhone(
            result.signupdatauser.CompanyPhone
              ? result.signupdatauser.CompanyPhone
              : ""
          );
          setcompanyEmail(
            result.signupdatauser.CompanyEmail
              ? result.signupdatauser.CompanyEmail
              : ""
          );
          setCompanyPinCode(
            result.signupdatauser.CompanyPincode
              ? result.signupdatauser.CompanyPincode
              : ""
          );
          setcompanyWebsite(
            result.signupdatauser.CompanyWebsite
              ? result.signupdatauser.CompanyWebsite
              : ""
          );
          setCompanyCountry(
            result.signupdatauser.CompanyCountry
              ? result.signupdatauser.CompanyCountry
              : ""
          );
          setCompanyFax(
            result.signupdatauser.CompanyFax
              ? result.signupdatauser.CompanyFax
              : ""
          );
          console.log(result.data);
        } else {
          console.log(result.message);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const updateProfile = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 88927950e8858bb239c62d8dcb81292630996841"
    );
    var formdata = new FormData();
    formdata.append("UserId", RequirementID);
    formdata.append("UserName", userName);
    formdata.append("FirstName", firstName);
    formdata.append("LastName", lastName);
    formdata.append("Country", country);
    formdata.append("State", state);
    formdata.append("City", city);
    formdata.append("Address", address);
    formdata.append("PinCode", pinCode);
    formdata.append("Email", email);
    formdata.append("Mobile", mobile);
    formdata.append("JobTitle", jobTitle);
    formdata.append("ProfilePicture", profileImage);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch("https://api.sasone.in/apiadmin/v1/UserProfileUpdate", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          setAlertMessage(result.message);
          setShow(true);
          setApiFailled(false);
        } else {
          setAlertMessage(result.message);
          setShow(true);
          setApiFailled(true);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const updateCompanyProfile = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 88927950e8858bb239c62d8dcb81292630996841"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      CompanyId: companyId,
      CompanyCountry: companyCountry,
      CompanyName: companyName,
      CompanyWebsite: companyWebsite,
      CompanyPhone: companyPhone,
      CompanyEmail: companyEmail,
      CompanyAddress: companyAddress,
      CompanyState: companyState,
      NumberOfEmployee: noOfEmployee,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://api.sasone.in/apiadmin/v1/UserComapnyUpdate", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          setAlertMessage(result.message);
          setShow(true);
          setApiFailled(false);
        } else {
          setAlertMessage(result.message);
          setShow(true);
          setApiFailled(true);
        }
      })
      .catch((error) => console.log("error", error));
  };
  const successMessage = () => {
    setTimeout(() => {
      setAlertMessage("");
      
      // navigate("/UserDetailInfo/"+RequirementID);
      
    }, 5000);
    return alertMessage;
  };

  const profileImageHandler = (e) => {
    setProfileImage(e.target.files[0]);
    setShowProfileImage(URL.createObjectURL(e.target.files[0]));
  };

  return (
    <>
      <Header />
      {alertMessage&&
      <SuccessMessage
        show={show}
        apiFailled={apiFailled}
        message={successMessage()}
        onClose={() => {
          setShow(false);
        }}
      />
}

      <div className="flex h-full">
        <div className="min-w-0 flex-1">
          <div className="flex flex-1 overflow-hidden">
            <main className="flex-1 overflow-y-auto focus:outline-none xl:order-last">
              <article>
                <div>
                  <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
                    <div className="mt-5 sm:mt-5 sm:flex sm:items-end sm:space-x-5">
                      <div className="flex items-center space-between space-x-8 sm:items-start">
                        <div className="h-32 w-32  flex justify-center items-center text-2xl font-semibold">
                          <div className="relative">
                            <div className="w-32 h-32 rounded-full text-white bg-slate-400 text-2xl font-bold flex items-center justify-center">
                              {!showProfileImage ? (
                                <div>
                                  {firstName.charAt(0).toUpperCase() +
                                    " " +
                                    lastName.charAt(0).toUpperCase()}
                                </div>
                              ) : (
                                <img
                                  src={showProfileImage}
                                  alt=""
                                  className="h-full w-full bg-contain rounded-full"
                                />
                              )}
                            </div>
                            {/* <div className={classes.errors}>{errors.profileImage}</div> */}
                            <div className="cursor-pointer">
                              {profileSection === "personalInfo" && (
                                <label
                                  for="1"
                                  className="absolute top-24 left-24 bg-zinc-200 rounded-full cursor-pointer"
                                >
                                  <IoIosCamera
                                    size="32px"
                                    color="black"
                                    className="p-1"
                                  />
                                </label>
                              )}
                              <input
                                type="file"
                                id="1"
                                className="w-0 absolute top-24 left-24 opacity-0"
                                onChange={(e) => {
                                  // errors.profileImage=''
                                  e.target.files[0].name.match(
                                    /\.(jpg|jpeg|png|gif)$/
                                  ) ? (
                                    profileImageHandler(e)
                                  ) : (
                                    <></>
                                  );
                                }}
                              />
                            </div>
                          </div>
                          {/*  {userProfile?.FirstName?.charAt(0).toUpperCase() +
                              " " +
                              userProfile?.LastName?.charAt(0).toUpperCase()} */}
                        </div>
                        <div className="space-y-2 sm:space-y-2 sm:pt-10">
                          <h1 className="truncate text-2xl font-bold text-gray-900">
                            {firstName} {lastName}
                            {/*  {userProfile?.FirstName +
                              " " +
                              userProfile?.LastName} */}
                          </h1>

                          <p className="max-w-2xl text-sm text-gray-500">
                            {/*  {userProfile?.JobTitle
                              ? userProfile.JobTitle
                              : <></>} */}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-6 sm:mt-2 2xl:mt-5">
                  {/* <div className="border-b border-gray-200">
                  <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
                    <nav
                      className="-mb-px flex space-x-8 cursor-pointer"
                      aria-label="Tabs"
                    >
                      <div className="border-transparent border-pink-500 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm">
                        Profile
                      </div>
                    </nav>
                  </div>
                </div> */}
                </div>

                <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
                  <div className="p-2">
                    <div className="space-y-6 py-3 pt-0 sm:space-y-5 sm:pt-0">
                      <div className="">
                        {profileSection === "personalInfo" ? (
                          <h3 className="text-gray-900 font-bold text-2xl">
                            Personal Information
                          </h3>
                        ) : (
                          <h3 className="text-gray-900 font-bold text-2xl">
                            Company Information
                          </h3>
                        )}
                      </div>
                    </div>
                    {profileSection === "personalInfo" ? (
                      <dl className="grid grid-cols-1 gap-x-4 gap-y-2 sm:grid-cols-2">
                        {Object.keys(profile.userInfo).map((field) => (
                          <div key={field} className="sm:col-span-1">
                            {/*  <dt className="text-sm font-medium text-gray-500">
                              {field}
                            </dt> */}
                            <dd className="mt-1 text-sm text-gray-900">
                              {profile.userInfo[field]}
                            </dd>
                          </div>
                        ))}
                      </dl>
                    ) : (
                      <dl className="grid grid-cols-1 gap-x-4 gap-y-2 sm:grid-cols-2">
                        {Object.keys(profile.companyInfo).map((field) => (
                          <div key={field} className="sm:col-span-1">
                            {/*  <dt className="text-sm font-medium text-gray-500">
                              {field}
                            </dt> */}
                            <dd className="mt-1 text-sm text-gray-900">
                              {profile.companyInfo[field]}
                            </dd>
                          </div>
                        ))}
                      </dl>
                    )}
                    <div className="flex flex-row-reverse space-x-4 space-x-reverse my-6">
                      <Button
                        type={"smallButton"}
                        buttonText={"Save"}
                        onClick={() => {
                          {
                            profileSection === "personalInfo" ? updateProfile() : updateCompanyProfile()
                          }

                        }}
                      />
                      <Button
                        type={"smallButton"}
                        buttonText={"Cancel"}
                        onClick={() => {
                          navigate(-1);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </article>
            </main>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfileEdit;
