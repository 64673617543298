import React, { useState, useEffect } from 'react'
import Header from '../Components/TailwindComponent/Header'
import DatePicker from "../Components/TailwindComponent/DatePicker";
import Pagination from '../Components/TailwindComponent/Pagination';
import DataNotFound from '../Components/TailwindComponent/DataNotFound';
import ExcelDownload from "../Components/ExcelDownload";
import List from '../Components/TailwindComponent/List';
import { useNavigate } from 'react-router-dom';
import Searchbar from '../Components/TailwindComponent/Searchbar';
import Modal from '../Components/TailwindComponent/Modal';


const BannerList = () => {

  const navigate = useNavigate()
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [maxPage, setMaxPage] = useState(1);
  const [searchPage, setSearchPage] = useState(1);
  const [count, setCount] = useState("");
  const [page, setPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [bannerId, setBannerId] = useState("")

  useEffect(() => {
    var date = new Date();
    date.setDate(date.getDate() - 30);

    let startDateformat =
      date.getDate().toString().length === 1
        ? "0" + date.getDate()
        : date.getDate();

    const monthFormat =
      date.getMonth() + 1 > 9
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1);

    var finalDate =
      date.getFullYear() + "-" + monthFormat + "-" + startDateformat;
    setStartDate(finalDate);

    var todayDate = new Date();
    todayDate.setDate(todayDate.getDate());

    let endDateformat =
      todayDate.getDate().toString().length === 1
        ? "0" + todayDate.getDate()
        : todayDate.getDate();
    const todaymonthFormat =
      todayDate.getMonth() + 1 > 9
        ? todayDate.getMonth() + 1
        : "0" + (todayDate.getMonth() + 1);

    var finalToday =
      todayDate.getFullYear() + "-" + todaymonthFormat + "-" + endDateformat;
    setEndDate(finalToday);
    BannerListTable(finalDate, finalToday, page);

  }, []);



  const BannerListTable = (end, today, currentpage) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 88927950e8858bb239c62d8dcb81292630996841"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({

      StartDate: end ? end : startDate,
      EndDate: today ? today : endDate,
      page: currentpage ? currentpage : page,

    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://api.sasone.in/apiadmin/v1/GetBannerlist",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {

          setData(result.data);
          setCount(result.count);
          setMaxPage(Math.ceil(result.count / 20));
        } else {
          console.log(result.message);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const DeleteBanner = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 88927950e8858bb239c62d8dcb81292630996841");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "BannerUUID": bannerId
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://api.sasone.in/apiadmin/v1/updateBannerdetails", requestOptions)
      .then(response => response.json())
      .then(result => {
        if(result.status_code===200&&result.status==="Success"){
          setOpenModal(false)
        }
      })
      .catch(error => console.log('error', error));
  }

  const pageHandler = (value) => {
    setSearchPage(value);
  };

  const OpenModal = (id) => {

    setOpenModal(true);
    setBannerId(id)

  };

  return (
    <>
      <Modal
        open={openModal}
        setOpen={setOpenModal}
        onClose={() => {
          setOpenModal(false);
          setBannerId("");
        }}
        type={"DeleteBanner"}

      onClick={()=>DeleteBanner()}
      // minValid={changeDate}
      />
      <Header />
      <div className="px-5 sm:px-8">
        <div className="sm:flex flex-col md:items-center items-start justify-between lg:flex-row m-auto md:w-5/6">
          <DatePicker
            SearchButton={() => {
              setSearchValue("");
              setSearchPage(1);
              setPage(1);
              BannerListTable();
            }}
            StartValue={startDate}
            EndValue={endDate}
            StartonChange={(e) => setStartDate(e.target.value)}
            EndonChange={(e) => setEndDate(e.target.value)}
          />
          {/* <Searchbar
            SearchButton={() => {
              setSearchPage(1);
              BannerListTable();
            }}
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
          /> */}
        </div>
        <div>
          <div className="border-b border-gray-200">
            <div className='mt-4'>
              {data.length > 0 ? (
                <>
                  <div className="flex items-center justify-end">
                    <button
                      type="submit"
                      className="ml-3 mr-5 h-10 border border-slate-200 px-6 py-2 bg-indigo-500 text-white rounded hover:bg-indigo-700 text-base font-semibold" onClick={() => navigate("/newBanner")}>Add New Banner</button>
                    <ExcelDownload csvData={data} fileName="Artcle Table" Excel={"Download Excel"} />
                  </div>
                  <List data={data} type={"Banner"}
                    modalOpen={(f) => OpenModal(f)} />
                  {
                    <Pagination
                      maxPages={maxPage}
                      dataCount={count}
                      datalength={data.length}
                      hitPage={pageHandler}
                      pageNum={searchPage}
                    />
                  }
                </>
              ) : (
                <DataNotFound />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BannerList