import React, { useState, useEffect } from 'react'
import Header from '../Components/TailwindComponent/Header'
import Pagination from '../Components/TailwindComponent/Pagination';
import DataNotFound from '../Components/TailwindComponent/DataNotFound';
import ExcelDownload from "../Components/ExcelDownload";
import List from '../Components/TailwindComponent/List';


const TotalSasOneData = () => {
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [data, setData] = useState([]);
    const [fulldata, setFullData] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [count, setCount] = useState("");
    const [page, setPage] = useState(1);
    const [searchPage, setSearchPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);

    useEffect(() => {
        if (searchValue === "") {
            signUpList(startDate, endDate, page);
        }

    }, [page, searchValue === ""]);

    const signUpList = (end, today, currentpage) => {
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Token 88927950e8858bb239c62d8dcb81292630996841"
        );
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({

            StartDate: end ? end : startDate,
            EndDate: today ? today : endDate,
            page: currentpage ? currentpage : page,

        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(
            "https://api.sasone.in/apiadmin/v1/SASOnedata",
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.status_code === 200 && result.status === "Success") {

                    setData(result.data);
                    setCount(result.count);
                    setMaxPage(Math.ceil(result.count / 20));
                } else {
                    console.log(result.message);
                }
            })
            .catch((error) => console.log("error", error));
    };

    const pageHandler = (value) => {
        if (searchValue === "") {
            setPage(value);
        }
        if (searchValue !== "") {
            setSearchPage(value);
        }
    };

    console.log(fulldata);
    return (
        <>
            <Header />
            <div className="px-5 sm:px-8">
                <div className="border-b border-gray-200">
                    <div className='mt-4'>
                        <ExcelDownload csvData={data} fileName="SAS_ONE All Data" Excel={"Download Excel"} />

                        {data.length > 0 ? (
                            <>
                                <List data={data} type={"TotalSasOneData"} />
                                {
                                    <Pagination
                                        maxPages={maxPage}
                                        dataCount={count}
                                        datalength={data.length}
                                        hitPage={pageHandler}
                                        pageNum={searchValue === "" ? page : searchPage}
                                    />
                                }
                            </>
                        ) : (
                            <DataNotFound />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default TotalSasOneData