import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import List from "../Components/TailwindComponent/List";
import Modal from "../Components/TailwindComponent/Modal";
import Addfaq from "./Addfaq";

const Faq = () => {
    const [data, setData] = useState([]);
    const [approve, setApproved] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const [fid, setFid] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    // console.log(data, "data");

    useEffect(() => {
        qnalist();
    }, []);

    const qnalist = () => {
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Token 88927950e8858bb239c62d8dcb81292630996841"
        );
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({});

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch("https://api.sasone.in/apiadmin/v1/Getfaqlist", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status_code === 200 && result.status === "Success") {
                    setData(result.data);
                } else {
                }
            })
            .catch((error) => console.log("error", error));
    };

    const deletefaq = () => {
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Token 88927950e8858bb239c62d8dcb81292630996841"
        );
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            ForumQuestionUUID: fid,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch("https://api.sasone.in/apiadmin/v1/DeleteFaqdetails", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status_code === 200 && result.status === "Success") {
                    setOpenModal(false);
                    qnalist();
                } else {
                }
            })
            .catch((error) => console.log("error", error));
    };

    const OpenModal = (id) => {
        setOpenModal(true);
        setFid(id);
    };

    return (
        <div>
            <Modal
                open={openModal}
                setOpen={setOpenModal}
                onClose={() => {
                    setOpenModal(false);
                    setFid("");
                }}
                type={"Deletefaq"}
                onClick={() => deletefaq()}
            />
            {location.search !== "?addfaq=addfaq" ? (
                <>
                    <div className="text-xl text-gray-900 p-3 font-medium">
                        Frequently asked Question
                    </div>
                    <div className="sm:flex  justify-end px-6 mt-4">
                        <div
                            class="cursor-pointer inline-flex items-center gap-2 rounded border border-indigo-600 bg-indigo-600 px-8 py-3 text-white hover:bg-transparent hover:text-indigo-600 focus:outline-none focus:ring active:text-indigo-500"
                            onClick={() => navigate("?addfaq=addfaq")}
                            state={{ currentTab: "Faq" }}
                        >
                            <span class="text-sm font-medium"> Add FaQ </span>

                            <svg
                                class="h-5 w-5"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                                />
                            </svg>
                        </div>
                    </div>
                    <div>
                        <List data={data} type={"Faq"} modalOpen={(f) => OpenModal(f)} />
                    </div>
                </>
            ) : (
                <Addfaq />
            )}
        </div>
    );
};

export default Faq;
