import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  CalendarIcon,
  CogIcon,
  HomeIcon,
  MagnifyingGlassCircleIcon,
  MapIcon,
  MegaphoneIcon,
  SquaresPlusIcon,
  UserGroupIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronLeftIcon,
  EnvelopeIcon,
  FunnelIcon,
  MagnifyingGlassIcon,
  PhoneIcon
} from "@heroicons/react/20/solid";

import DataNotFound from "./DataNotFound";
import { BsCheck } from "react-icons/bs";
import { FaEdit } from "react-icons/fa"
import Modal from "./Modal";


const user = {
  name: "Tom Cook",
  imageUrl:
    "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
};
const navigation = [
  { name: "Dashboard", href: "#", icon: HomeIcon, current: false },
  { name: "Calendar", href: "#", icon: CalendarIcon, current: false },
  { name: "Teams", href: "#", icon: UserGroupIcon, current: false },
  {
    name: "Directory",
    href: "#",
    icon: MagnifyingGlassCircleIcon,
    current: true,
  },
  { name: "Announcements", href: "#", icon: MegaphoneIcon, current: false },
  { name: "Office Map", href: "#", icon: MapIcon, current: false },
];
const secondaryNavigation = [
  { name: "Apps", href: "#", icon: SquaresPlusIcon },
  { name: "Settings", href: "#", icon: CogIcon },
];
const tabs = [
  { name: "Profile", href: "#", current: true },
  { name: "Subcription Data", href: "#", current: false },
  { name: "Payment Data", href: "#", current: false },
 
];
const profile = {
  name: "Ricardo Cooper",
  imageUrl:
    "https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
  coverImageUrl:
    "https://images.unsplash.com/photo-1444628838545-ac4016a5418a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
  about: `
    <p>Tincidunt quam neque in cursus viverra orci, dapibus nec tristique. Nullam ut sit dolor consectetur urna, dui cras nec sed. Cursus risus congue arcu aenean posuere aliquam.</p>
    <p>Et vivamus lorem pulvinar nascetur non. Pulvinar a sed platea rhoncus ac mauris amet. Urna, sem pretium sit pretium urna, senectus vitae. Scelerisque fermentum, cursus felis dui suspendisse velit pharetra. Augue et duis cursus maecenas eget quam lectus. Accumsan vitae nascetur pharetra rhoncus praesent dictum risus suspendisse.</p>
  `,
  fields: {
    Phone: "(555) 123-4567",
    Email: "ricardocooper@example.com",
    Title: "Senior Front-End Developer",
    Team: "Product Development",
    Location: "San Francisco",
    Sits: "Oasis, 4th floor",
    Salary: "$145,000",
    Birthday: "June 8, 1990",
  },
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Directory = (props) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [tab, setTab] = useState('Profile');


  const dateHandler = (date) => {
    const dateObj = new Date(date);
    const day = dateObj.getDate();
    const monthNameLong = dateObj.toLocaleString("en-US", { month: "short" });
    const year = dateObj.getFullYear();
    return (day <= 9 ? "0" : "") + day + "-" + monthNameLong + "-" + year;
  };

  return (
    <>
      {props.openNote && <Modal type={"Notes"} setOpen={props.setOpen} onClose={props.onClose} open={props.openModal}
        value={props.noteValue} onChange={props.noteonChange} onClick={props.noteonClick} />}
      <div className="flex h-full">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white focus:outline-none">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                    <div className="flex flex-shrink-0 items-center px-4">
                      <img
                        className="h-8 w-auto"
                        src="https://tailwindui.com/img/logos/mark.svg?color=pink&shade=500"
                        alt="Your Company"
                      />
                    </div>
                    <nav aria-label="Sidebar" className="mt-5">
                      <div className="space-y-1 px-2">
                        {navigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              item.current
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                              "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                            )}
                            aria-current={item.current ? "page" : undefined}
                          >
                            <item.icon
                              className={classNames(
                                item.current
                                  ? "text-gray-500"
                                  : "text-gray-400 group-hover:text-gray-500",
                                "mr-4 h-6 w-6"
                              )}
                              aria-hidden="true"
                            />
                            {item.name}
                          </a>
                        ))}
                      </div>
                      <hr
                        className="my-5 border-t border-gray-200"
                        aria-hidden="true"
                      />
                      <div className="space-y-1 px-2">
                        {secondaryNavigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className="group flex items-center rounded-md px-2 py-2 text-base font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900"
                          >
                            <item.icon
                              className="mr-4 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </nav>
                  </div>
                  <div className="flex flex-shrink-0 border-t border-gray-200 p-4">
                    <a href="#" className="group block flex-shrink-0">
                      <div className="flex items-center">
                        <div>
                          <img
                            className="inline-block h-10 w-10 rounded-full"
                            src={user.imageUrl}
                            alt=""
                          />
                        </div>
                        <div className="ml-3">
                          <p className="text-base font-medium text-gray-700 group-hover:text-gray-900">
                            {user.name}
                          </p>
                          <p className="text-sm font-medium text-gray-500 group-hover:text-gray-700">
                            View profile
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        <div className="flex min-w-0 flex-1 flex-col overflow-hidden">
          <div className="lg:hidden">
            <div className="flex items-center justify-between border-b border-gray-200 bg-gray-50 px-4 py-1.5">
              <div>
                <img
                  className="h-8 w-auto"
                  src="https://tailwindui.com/img/logos/mark.svg?color=pink&shade=500"
                  alt="Your Company"
                />
              </div>
              <div>
                <button
                  type="button"
                  className="-mr-3 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-pink-600"
                  onClick={() => setSidebarOpen(true)}
                >
                  <span className="sr-only">Open sidebar</span>
                  <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
          <div className="relative z-0 flex flex-1 overflow-hidden">
            <main className="relative z-0 flex-1 overflow-scroll h-screen pb-8 focus:outline-none xl:order-last">

              <nav
                className="flex items-start px-4 py-3 sm:px-6 lg:px-8 xl:hidden"
                aria-label="Breadcrumb"
              >
                <a
                  href="#"
                  className="inline-flex items-center space-x-3 text-sm font-medium text-gray-900"
                >
                  <ChevronLeftIcon
                    className="-ml-2 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <span>Directory</span>
                </a>
              </nav>

              {(props.profile?.FirstName || props.profile?.LastName) && <article>
                <div>
                  <div>
                    <img
                      className="h-32 w-full object-cover lg:h-48"
                      src={profile.coverImageUrl}
                      alt=""
                    />
                  </div>
                  <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
                    <div className="-mt-14 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">

                      <div className="flex">
                        {props.profile?.ProfilePicture ?
                          <img
                            className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32"
                            src={props.profile.ProfilePicture}
                            alt={props.profile?.FirstName + " " + props.profile?.LastName}
                          /> : <div className="h-32 w-32 bg-gray-100 rounded-full flex justify-center items-center text-2xl font-semibold">
                            {props.profile?.FirstName.charAt(0).toUpperCase() + " " + props.profile?.LastName.charAt(0).toUpperCase()}
                          </div>}
                      </div>
                      <div className="mt-6 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                        <div className="mt-6 min-w-0 flex-1 sm:hidden 2xl:block pl-2">
                          <h1 className="truncate text-2xl font-bold text-gray-900">
                            {props.profile?.FirstName + " " + props.profile?.LastName}
                          </h1>
                        </div>
                        <div className="justify-stretch mt-6 flex flex-col space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
                          <a
                            href={"mailto:" + `${props.profile?.Email}`}
                            target={"_blank"}>
                            <button
                              type="button"
                              className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2"
                            >
                              <EnvelopeIcon
                                className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                              <span>Message</span>
                            </button>
                          </a>
                          <a href={"tel:" + `${props.profile?.Mobile}`}>
                            <button
                              type="button"
                              className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2"
                            >
                              <PhoneIcon
                                className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                              <span>Call</span>
                            </button>
                          </a>
                          <div onClick={props.openNoteText}>
                            <button
                              type="button"
                              className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2"
                            >
                              <FaEdit
                                className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                              <span>Notes</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-6 hidden min-w-0 flex-1 sm:block 2xl:hidden">
                      <h1 className="truncate text-2xl font-bold text-gray-900">
                        {props.profile?.FirstName + " " + props.profile?.LastName}
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="mt-6 sm:mt-2 2xl:mt-5">
                  <div className="border-b border-gray-200">
                    <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
                      <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                        {tabs.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            onClick={() => setTab(item.name)}
                            className={classNames(
                              item.name === tab
                                ? "border-pink-500 text-gray-900"
                                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                              "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                            )}
                            aria-current={item.name ? "page" : undefined}
                          >
                            {item.name}
                          </a>
                        ))}
                      </nav>
                    </div>
                  </div>
                </div>
                <div className="mx-auto mt-6 max-w-5xl px-4 sm:px-6 lg:px-8">
                  {tab === "Profile" && <>
                    <div className="mt-4 rounded border-4 border-gray-100 p-5">
                      <div className="space-y-3 pt-1 pb-3 sm:space-y-3 sm:pt-0">
                        <div>
                          <h3 className="text-gray-900 font-bold text-2xl">
                            Personal Information
                          </h3>
                        </div>
                      </div>

                      <dl className="grid grid-cols-1 gap-x-4 gap-y-2 sm:grid-cols-2">
                        
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Full Name
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {(props.Userprofile.FirstName || props.Userprofile.LastName) && <div className="flex items-center">{props.Userprofile.FirstName + " " + props.Userprofile.LastName}</div>}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Phone
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {props.Userprofile.Mobile}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Email
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {props.Userprofile.IsEmailVerified === "1" ? <div className="flex items-center">{props.Userprofile.Email}<span className=""><BsCheck size={22} color={"green"} /></span></div> : props.Userprofile.Email}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Job title
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {props.Userprofile.JobTitle}
                          </dd>
                        </div>
                       
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Location
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {props.Userprofile?.Address && props.Userprofile.Address + "," + props.Userprofile.State + "," + props.Userprofile.Country + "," + props.Userprofile.PinCode}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            User Name
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {props.Userprofile.UserName && <div className="flex items-center">{props.Userprofile.UserName}</div>}
                          </dd>
                        </div>
                      
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Source
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {props.Userprofile?.Source && props.Userprofile?.Source}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Device
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {props.Userprofile?.device && props.Userprofile?.device}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Promo code
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {props.Userprofile?.promocode && props.Userprofile?.promocode}
                          </dd>
                        </div>
                      </dl>
                    </div>
                    <div className="mt-4 rounded border-4 border-gray-100 p-5">
                      <div className="space-y-6 pt-1 pb-3 pt-0 sm:space-y-3 sm:pt-0">
                        <div>
                          <h3 className="text-gray-900 font-bold text-2xl">
                            Company Information
                          </h3>
                        </div>
                      </div>
                      <dl className="grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-2">
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Company Name
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {props.Userprofile.CompanyName}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Company Email
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {props.Userprofile.CompanyEmail}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Company Phone
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {props.Userprofile.CompanyPhone}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Company Website
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {props.Userprofile.CompanyWebsite}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Number Of Employee
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {props.Userprofile.NumberOfEmployee}
                          </dd>
                        </div>
                       
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Company Location
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {props.Userprofile?.CompanyAddress && props.Userprofile.CompanyAddress + "," + props.Userprofile.CompanyState + "," + props.Userprofile.CompanyCountry + "," + props.Userprofile.CompanyPincode}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            GST Number
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {props.Userprofile.GSTIN}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            PAN Number
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {props.Userprofile.PAN}
                          </dd>
                        </div>

                      </dl>
                    </div>

                  </>}
                  {tab === "Subcription Data" && <>
                    {props.subcriptiondata.length ? props.subcriptiondata?.map((item) => {
                      return (
                        <dl className="mt-4 rounded border-4 border-gray-100 p-5 grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2 my-3 p-3 rounded">

                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              Product Name
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {item.ProductName}
                            </dd>
                          </div>
                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              Total Amount
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {item.TotalAmount}
                            </dd>
                          </div>
                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              Subscription Type
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {item.SubscriptionType}
                            </dd>
                          </div>
                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              Service Type
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {item.ServiceType}
                            </dd>
                          </div>
                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              Valid From
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {item.ValidFrom && dateHandler(item.ValidFrom)}
                            </dd>
                          </div>
                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              Valid To
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {item.ValidTo && dateHandler(item.ValidTo)}
                            </dd>
                          </div>
                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              Promo code
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {item.promocode && item.promocode}
                            </dd>
                          </div>
                        </dl>
                      )
                    }) : <DataNotFound />}</>}
                  {tab === "Payment Data" && <>
                    {props.paymentData.length ? props.paymentData?.map((item) => {
                      return (
                        <dl className="mt-4 rounded border-4 border-gray-100 p-5 grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2 my-3 p-3 rounded">

                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              Payment Status
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {item.PaymentStatus}
                            </dd>
                          </div>
                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              GST
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {item.GST}
                            </dd>
                          </div>
                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              Amount
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {item.PGAmount}
                            </dd>
                          </div>
                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              Payment Method
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {item.PGMethod}
                            </dd>
                          </div>
                         
                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              Discount
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {item.Discount}
                            </dd>
                          </div>
                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              Updated At
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {dateHandler(item.UpdatedAt)}
                            </dd>
                          </div>
                        </dl>
                      )
                    }) : <DataNotFound />}</>}
                 
                </div>

                {/* Team member list */}
                {/* <div className="mx-auto mt-8 max-w-5xl px-4 pb-12 sm:px-6 lg:px-8">
                  <h2 className="text-sm font-medium text-gray-500">
                    Team members
                  </h2>
                  <div className="mt-1 grid grid-cols-1 gap-4 sm:grid-cols-2">
                    {team.map((person) => (
                      <div
                        key={person.handle}
                        className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-pink-500 focus-within:ring-offset-2 hover:border-gray-400"
                      >
                        <div className="flex-shrink-0">
                          <img
                            className="h-10 w-10 rounded-full"
                            src={person.imageUrl}
                            alt=""
                          />
                        </div>
                        <div className="min-w-0 flex-1">
                          <a href="#" className="focus:outline-none">
                            <span
                              className="absolute inset-0"
                              aria-hidden="true"
                            />
                            <p className="text-sm font-medium text-gray-900">
                              {person.name}
                            </p>
                            <p className="truncate text-sm text-gray-500">
                              {person.role}
                            </p>
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                </div> */}
              </article>}
            </main>
            <aside className="overflow-scroll h-screen pb-8 hidden w-96 flex-shrink-0 border-r border-gray-200 xl:order-first xl:flex xl:flex-col">
              <div className="px-6 pt-6 pb-4">
                <h2 className="text-lg font-medium text-gray-900">Directory</h2>
                <p className="mt-1 text-sm text-gray-600">
                  Search directory of {props.count} employees
                </p>
                <div className="mt-6 flex space-x-4" >
                  <div className="min-w-0 flex-1">
                    <label htmlFor="search" className="sr-only">
                      Search
                    </label>
                    <div className="relative rounded-md shadow-sm">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <MagnifyingGlassIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <input
                        type="search"
                        name="search"
                        id="search"
                        className="block w-full rounded-md border-gray-300 pl-10 focus:border-pink-500 focus:ring-pink-500 sm:text-sm"
                        placeholder="Search"
                        value={props.value}
                        onChange={props.onChange}
                      />
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-3.5 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2"
                    onClick={props.onClick}
                  >
                    <FunnelIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Search</span>
                  </button>
                </div>
              </div>
              <nav
                className="min-h-0 flex-1 overflow-y-auto"
                aria-label="Directory"
              >

                <ul
                  role="list"
                  className="relative z-0 divide-y divide-gray-200"
                >
                  {props.directory?.length ? props.directory?.map((person) => (
                    <li key={person.id}>
                      <div className="relative flex items-center space-x-3 px-6 py-2 focus-within:ring-2 focus-within:ring-inset focus-within:ring-pink-500 hover:bg-gray-50">
                        {props.directory.ProfilePicture ? <div className="flex-shrink-0">
                          <img
                            className="h-6 w-6 rounded-full"
                            src={person.ProfilePicture}
                            alt=""
                          />
                        </div> : <div className="flex-shrink-0">
                          <div className="border border-indigo-600 rounded-full py-2 px-2">
                            {person.FirstName.charAt(0).toUpperCase() + " " + person.LastName.charAt(0).toUpperCase()}
                          </div>
                        </div>}
                        <div className="min-w-0 flex-1">
                          <div className="cursor-pointer focus:outline-none"
                            onClick={() => props.UserDetails(person.UserId)}
                          >

                            <span
                              className="absolute inset-0"
                              aria-hidden="true"
                            />
                            <p className="text-sm font-medium text-gray-900">
                              {person.FirstName + " " + person.LastName}
                            </p>
                            <p className="truncate text-sm text-gray-500">
                              {person.JobTitle}
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                  )) : <DataNotFound />}
                </ul>

              </nav>
            </aside>
          </div>
        </div>
      </div>
    </>
  );
};

export default Directory;