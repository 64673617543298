import React, { useState, useEffect } from "react";
import DatePicker from "../Components/TailwindComponent/DatePicker";
import List from "../Components/TailwindComponent/List";
import Searchbar from "../Components/TailwindComponent/Searchbar";
import Pagination from "../Components/TailwindComponent/Pagination";
import DataNotFound from "../Components/TailwindComponent/DataNotFound";
import Modal from "../Components/TailwindComponent/Modal";
import SuccessMessage from "../Components/TailwindComponent/SuccessMessage";
import ExcelDownload from "../Components/ExcelDownload";
import { useNavigate, useLocation } from "react-router-dom";
import NewPayment from "./NewPayment";

const PaymentDetails = (props) => {

    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [maxPage, setMaxPage] = useState(1);
    const [searchPage, setSearchPage] = useState(1);
    const [count, setCount] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const [email, setEmail] = useState("")
    const [show, setShow] = useState(false);
    const [error, setError] = useState("");
    const [userID, setUserID] = useState("")
    const [apiFailled, setApiFailled] = useState(false);
    const [alertMessage, setAlertMessage] = useState("Status Updated");
    const location = useLocation();

    console.log(location.search === "?newPayment=newPayment")

    useEffect(() => {
        var date = new Date();
        date.setDate(date.getDate() - 30);

        let startDateFormat =
            date.getDate().toString().length === 1
                ? "0" + date.getDate()
                : date.getDate();

        const monthFormat =
            date.getMonth() + 1 > 9
                ? date.getMonth() + 1
                : "0" + (date.getMonth() + 1);

        var finalDate =
            date.getFullYear() + "-" + monthFormat + "-" + startDateFormat;

        setStartDate(finalDate);

        var todayDate = new Date();
        todayDate.setDate(todayDate.getDate());

        let endDateFormat =
            todayDate.getDate().toString().length === 1
                ? "0" + todayDate.getDate()
                : todayDate.getDate();

        const todayMonthFormat =
            todayDate.getMonth() + 1 > 9
                ? todayDate.getMonth() + 1
                : "0" + (todayDate.getMonth() + 1);

        var finalToday =
            todayDate.getFullYear() + "-" + todayMonthFormat + "-" + endDateFormat;
        setEndDate(finalToday);
    }, []);

    useEffect(() => {
        fetchOnboardingList()
    }, [searchPage])

    const fetchOnboardingList = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 88927950e8858bb239c62d8dcb81292630996841");

        var formdata = new FormData();
        formdata.append("page", searchPage);
        formdata.append("StartDate", startDate);
        formdata.append("EndDate", endDate);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch("https://api.sasone.in/apiadmin/v1/GetPrisingMasterDataBylist", requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                if (result.status_code === 200 && result.status === "Success") {
                    setData(result.data);
                    setCount(result.count);
                    setMaxPage(Math.ceil(result.count / 10));
                }
            })
            .catch(error => console.log('error', error));
    };

    const pageHandler = (value) => {
        setSearchPage(value);
    };

    // useEffect(() => {
    //     PaymentDetailsList();
    // }, []);

    // const PaymentDetailsList = (end, today) => {
    //     var myHeaders = new Headers();
    //     myHeaders.append(
    //         "Authorization",
    //         "Token 88927950e8858bb239c62d8dcb81292630996841"
    //     );
    //     myHeaders.append("Content-Type", "application/json");

    //     var raw = "";
    //     var requestOptions = {
    //         method: "POST",
    //         headers: myHeaders,
    //         body: raw,
    //         redirect: "follow",
    //     };

    //     fetch(
    //         "https://api.sasone.in/apiadmin/v1/GetPrisingMasterDataBylist",
    //         requestOptions
    //     )
    //         .then((response) => response.json())
    //         .then((result) => {
    //             if (result.status_code === 200 && result.status === "Success") {

    //                 setData(result.data);
    //                 setCount(result.count);
    //                 setMaxPage(Math.ceil(result.count / 20));
    //             } else {
    //                 console.log(result.message);
    //             }
    //         })
    //         .catch((error) => console.log("error", error));
    // };



    const OpenModal = (id) => {
        setOpenModal(true)
        setUserID(id)
    }

    const UpdateSalesExecutive = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 88927950e8858bb239c62d8dcb81292630996841");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "UserId": userID,
            "SalesExecutive": email
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://api.sasone.in/apiadmin/v1/SalesExecutiveUpdate", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status_code === 200 && result.status === "Success" && result.message === "Data Updated Successfully") {
                    setOpenModal(false)
                    setAlertMessage("Update Successfully!");
                    setApiFailled(false);
                    setShow(true);
                    // PaymentDetailsList()
                }
                else {
                    setOpenModal(false)
                    setAlertMessage("Status Failed!");
                    setApiFailled(true);
                    setShow(true);
                }
            })
            .catch(error => console.log('error', error));
    }

    return (
        <>
            <Modal
                open={openModal}
                setOpen={setOpenModal}
                onClose={() => {
                    setOpenModal(false);
                    setUserID("")
                }}
                type={"UpdateSales"}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onSubmit={UpdateSalesExecutive}
            />
            <SuccessMessage
                show={show}
                apiFailled={apiFailled}
                message={alertMessage}
                onClose={() => {
                    setShow(false);
                }}
            />
            {
                location.search !== "?newPayment=newPayment" ?
                    <>
                        <div className="sm:flex flex-col md:items-center items-start justify-between lg:flex-row m-auto md:w-5/6">
                            <DatePicker
                                SearchButton={() => {
                                    setSearchValue("");
                                    setSearchPage(1);
                                    fetchOnboardingList();
                                }}
                                StartValue={startDate}
                                EndValue={endDate}
                                StartonChange={(e) => setStartDate(e.target.value)}
                                EndonChange={(e) => setEndDate(e.target.value)}
                            />
                            <Searchbar
                                SearchButton={() => {
                                    setSearchPage(1);
                                    fetchOnboardingList();
                                }}
                                value={searchValue}
                                onChange={(e) => {
                                    setSearchValue(e.target.value);
                                }}

                            />
                        </div>
                        {data.length > 0 ? (
                            <>
                                <div className="flex items-center justify-end">
                                    <button
                                        type="submit"
                                        className="ml-3 mr-5 h-10 border border-slate-200 px-6 py-2 bg-indigo-500 text-white rounded hover:bg-indigo-700 text-base font-semibold" onClick={() => navigate("?newPayment=newPayment")}>Onboarding New User</button>
                                    <ExcelDownload csvData={data} fileName="Onboarding List" Excel={"Download Excel"} />
                                </div>
                                <List data={data} type={"PaymentDetailList"} modalOpen={(i) => OpenModal(i)} />
                                {
                                    <Pagination
                                        maxPages={maxPage}
                                        dataCount={count}
                                        datalength={data.length}
                                        hitPage={pageHandler}
                                        pageNum={searchPage}
                                    />
                                }
                            </>
                        ) : (
                            <DataNotFound />
                        )}
                    </> : 
                    <NewPayment />
            }
        </>
    );
};
export default PaymentDetails;
