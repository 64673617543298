import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from '../Components/TailwindComponent/Header'
import Modal from '../Components/TailwindComponent/Modal'

const AddBanner = () => {

const [sNo,setSNo] = useState("")
const [pageNum,setPageNum] = useState("")
const [desktopImg,setDesktopImg] = useState("")
const [mobileImg,setMobileImg] = useState("")
const [live,setLive] = useState("")
const [openModal, setOpenModal] = useState(false);

const router=useNavigate()
const AddNewBanner=()=>{
    var myHeaders = new Headers();
myHeaders.append("Authorization", "Token 88927950e8858bb239c62d8dcb81292630996841");


var formdata = new FormData();

formdata.append("DesktopImageURL",desktopImg);
formdata.append("SNo", sNo);
formdata.append("PageName", pageNum);
formdata.append("MobileImage",mobileImg);
formdata.append("IsLive", live?1:0);

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: formdata,
  redirect: 'follow'
};

fetch("https://api.sasone.in/apiadmin/v1/InsertBnannerdetail", requestOptions)
  .then(response => response.json())
  .then(result => {
    if(result.status_code===200&&result.status==="Success"&&result.message=== "Data saved Successfully"){
        setOpenModal(true)
    }
    else{
        alert("failed")
    }
  })
  .catch(error => console.log('error', error));
}

    return (
        <>
        {openModal&& <Modal
        open={openModal}
        setOpen={setOpenModal}
        onClose={() => {
          setOpenModal(false);
          router("/banner-list")
        }}
        type={"SuccessBanner"}

    //   onClick={()=>DeleteBanner()}
      // minValid={changeDate}
      />}
            <Header />
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-0">
                <div className='flex items-center justify-between my-5 bg-zinc-100 p-2.5'>
                    <h1 className="text-left text-xl font-bold pl-2">New Article</h1>
                    <div className="flex items-center">
                        <div className="mr-3 text-base">
                            <label htmlFor="isLive" className="font-medium text-gray-700">Live: </label>
                        </div>
                        <div className="flex h-5 items-center">
                            <input id="isLive" name="live" type="checkbox" className="h-5 w-5 rounded border-gray-300 text-indigo-600 mr-10 focus:ring-indigo-500"
                            checked={live} onChange={(e)=>setLive(e.target.value)}
                            />
                        </div>

                    </div>
                </div>
                <div className='border w-full md:w-[55%] mx-auto md:mt-20 p-8 rounded-md grid grid-cols-1 sm:grid-cols-2 gap-2 bg-white shadow-md'>
                <div className=''>
                    <label htmlFor="active-image" className="block text-base font-medium text-gray-700">
                        Serial number:
                    </label>
                    <input type={"text"} id="active-image" placeholder='Serial number'
                        className='border-gray-200 w-[99%] border text-md  my-2 p-2 hover:border-indigo-300'
                        value={sNo} onChange={(e)=>setSNo(e.target.value)}
                    />
                </div>
                <div>
                    <label htmlFor="active-image" className="block text-base font-medium text-gray-700">
                        Page name:
                    </label>
                    <input type={"text"} id="active-image" placeholder='Page name'
                        className='border-gray-200 border text-md  my-2 p-2 hover:border-indigo-300  w-[99%]'
                        value={pageNum} onChange={(e)=>setPageNum(e.target.value)}
                    />
                </div>
                <div>
                    <label htmlFor="active-image" className="block text-base font-medium text-gray-700">
                        Desktop Image:
                    </label>
                    <input type={"file"} id="active-image" onChange={(e)=>setDesktopImg(e.target.files[0])}
                        accept="image/webp,image/png, image/jpeg, image/jpg, .gif, image/.svg, video/*" 
                        className='border-solid border text-md mx-0 my-2 p-2 hover:border-indigo-300 cursor-pointer flex justify-between w-[99%]'
                    />
                </div>
                <div>
                    <label htmlFor="active-image" className="block text-base font-medium text-gray-700">
                        Mobile Image:
                    </label>
                    <input type={"file"} id="active-image" onChange={(e)=>setMobileImg(e.target.files[0])}
                        accept="image/webp,image/png, image/jpeg, image/jpg, .gif, image/.svg, video/*" 
                        className='border-solid border text-md mx-0 my-2 p-2 hover:border-indigo-300 cursor-pointer flex justify-between w-[99%]'
                    />
                </div>
                </div>
                <div className='flex justify-center mt-5 items-center'>
                    <a href="/banner-list" className='text-indigo-500 text-base font-semibold pr-4'>Cancel</a>
                    <div className=' rounded bg-indigo-500 hover:bg-indigo-700 cursor-pointer w-24 text-white text-base font-medium text-center py-1.5 px-3'
                    onClick={()=>AddNewBanner()}>Submit</div></div>
            </div>
        </>
    )
}

export default AddBanner