const Badges = (props) => {
    return (
      <>
        <span className="inline-flex items-center h-15 w-15 mr-2 mt-2  py-0.5 pl-2.5 pr-1 text-sm font-medium text-red-700">
          <div className=" border-2 border-red-500 rounded-md">
            <div className="h-16 w-16">
              <img
                src={
                 URL.createObjectURL(props.item)
                }
                alt=""
                className="object-cover h-16 w-full rounded-md"
              />
            </div>
          </div>
          <button
            onClick={props.onClick}
            type="button"
            className="ml-0.5 -ml-2.5 -mt-16 bg-red-500 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-white hover:bg-red-400 hover:text-white focus:bg-red-500 focus:text-white focus:outline-none"
          >
            <svg
              className="h-2 w-2"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 8 8"
            >
              <path
                strokeLinecap="round"
                strokeWidth="1.5"
                d="M1 1l6 6m0-6L1 7"
              />
            </svg>
          </button>
        </span>
      </>
    );
  };
  export default Badges;
  