
import React, { useEffect, useState } from 'react'
import { AiFillYoutube } from 'react-icons/ai'
import Header from '../Components/TailwindComponent/Header'
// const {google} = require('googleapis');
// const blogger = google.blogger({
//   version: 'v3',
//   auth: '834036704623-n2nvtr8ajaujaif0skfrihocv4h654ju.apps.googleusercontent.com'
// });

// const params = {
//   blogId: '3213900'
// };
// async function runSample() {
//   const res = await blogger.blogs.get(params);
//   console.log(`The blog url is ${res.data.url}`);
// }
// runSample().catch(console.error);
const GMBAccount = () => {
    const[data,setData]=useState("")
    const[url,setUrl]=useState("")
    const[account,setAccount]=useState([])
    const[error,setError]=useState("")


    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

useEffect(() => {
 getToken();
 params.state&& addCred();
}, [params.state])

const addCred=()=>{
  var myHeaders = new Headers();
myHeaders.append("Authorization", "Token 88927950e8858bb239c62d8dcb81292630996841");
myHeaders.append("Content-Type", "application/json");

var raw = JSON.stringify({
  "state":params.state,
  "authURL": window.location.href
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch("https://api.sasone.in/apiadmin/v1/onepost/GetGMBToken", requestOptions)
  .then(response => response.json())
  .then(result =>{
    if(result.status==="Success"&&result.status_code===200){
      setData(result.data)
      AddCredentials(result.data.token);
      console.log(`Bearer ${result.data.token}`);
    }
  })
  .catch(error => console.log('error', error));
}
const getToken=()=>{
var myHeaders = new Headers();
myHeaders.append("Authorization", "Token 88927950e8858bb239c62d8dcb81292630996841");
myHeaders.append("Content-Type", "application/json");

var formdata = new FormData();

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: formdata,
  redirect: 'follow'
};

fetch("https://api.sasone.in/apiadmin/v1/onepost/GetGMBAuthUrl", requestOptions)
  .then(response => response.json())
  .then(result => {
    if(result.status==="Success"&&result.status_code===200){
    setUrl(result.url)
    console.log(result);
    }
  })
  .catch(error => console.log('error', error));
}
const AddCredentials=(token)=>{
  var myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${token}`);

var raw = "";

var requestOptions = {
  method: 'GET',
  headers: myHeaders,
  redirect: 'follow'
};

fetch("https://mybusinessaccountmanagement.googleapis.com/v1/accounts", requestOptions)
  .then(response => response.json())
  .then(result => {
      setAccount(result.accounts)
      console.log(result);
  })
  .catch(error => console.log(error,"abc"));
}
  return (
    <div>
    <Header />
    <div className=" w-80 p-2 sm:p-6 mb-8 rounded">
    <div
    // key={key}
    className={url?"bg-white flex flex-col border-2 p-4 justify-between":"bg-white flex flex-col border-2 p-4 justify-between opacity-30 pointer-events-none"}
  >
    <div className="relative flex items-center flex-col w-full  ">
  
    <a href={url} target="_blank"> <img src='./assets/gmbLogo.png'  className='h-16 w-16' /></a>
  
      <div className="relative mt-4 px-4">
      <a href={url} target="_blank"><h3 className="text-lg font-bold  text-gray-900">
          {"Google"}
        </h3> </a>
  
      </div>
      <div className="relative mt-1 px-4">
        <h3 className="text-sm font-normal text-slate-400">
          {"Bussiness"}
        </h3>
      </div>
    </div>
    <div className={"mt-6"}>
      <a  href={url} target="_blank" className="relative flex items-center justify-center  py-2 px-8 text-sm font-bold  cursor-pointer text-gray-500 hover:tex-gray-300"
      >
         Connect

      </a>
    </div>
  </div></div>
  
  
  <div className='w-80 p-2 sm:p-6 mb-8 rounded'>
    <div>Token:-{data.token}</div>
    <div>refresh_token:-{data.refresh_token}</div>
    <div>token_uri:-{data.token_uri}</div>
    <div>client_id:-{data.client_id}</div>
    <div>client_secret:-{data.client_secret}</div>

    
    </div>
    <div className='w-80 p-2 sm:p-6 mb-8 rounded'>
      { account?.map((item)=>{
        return (
          <>
    <div>name:-{item.name}</div>

    <div>accountName:-{item.accountName}</div>
    <div>type:-{item.type}</div>
    <div>verificationState:-{item.verificationState}</div>
    <div>vettedState:-{item.vettedState}</div></>
        )
      })}
    </div>
    
    </div>

  )
}

export default GMBAccount