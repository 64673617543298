import React, { useState } from "react";
import SignupData from "../../Screens/SignupData";
import LoginData from "../../Screens/LoginData";
import FreeTrail from "../../Screens/FreeTrail";
import Subscription from "../../Screens/Subscription";
import PaymentAttempt from "../../Screens/PaymentAttempt";
import Career from "../../Screens/Career";
import RequestADemo from "../../Screens/RequestADemo";
import SalesExecutive from "../../Screens/SalesExecutive";
import PaymentDetails from "../../Screens/PaymentDetails";
import Faq from "../../Screens/Faq";
import CustomerFormList from "../../Screens/CustomerFormList";



function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const DashboardTab = (props) => {
  const [currentTab, setCurrentTab] = useState("Signup");
  const [selectTab, setSelectTab] = useState(0);


  function changeTab(tab) {
    console.log("Current Tab:", tab);
    setCurrentTab(tab);
  }

  const tab = (e) => {
    switch (e.target.value) {
      case "Signup":
        {
          setCurrentTab("Signup");
          setSelectTab(0);
          break;
        }
      case "Login":
        {
          setCurrentTab("Login");
          setSelectTab(1);
          break;
        }
      case "Free Trial":
        {
          setCurrentTab("Free Trial");
          setSelectTab(2);
          break;
        }
      case "Subscription":
        {
          setCurrentTab("Subscription");
          setSelectTab(3);
          break;
        }
      case "Payment Attempt":
        {
          setCurrentTab("Payment Attempt");
          setSelectTab(4);
          break;
        }
      case "Career":
        {
          setCurrentTab("Career");
          setSelectTab(5);
          break;
        }
      // case "Request A Demo":
      //   {
      //     setCurrentTab("Request A Demo");
      //     setSelectTab(6);
      //   break;
      //   }
      case "Sales Details":
        {
          setCurrentTab("Sales Details");
          setSelectTab(7);
          break;
        }
      case "Onboarding":
        {
          setCurrentTab("Onboarding");
          setSelectTab(8);
          break;
        }
      case "Faq":
        {
          setCurrentTab("Faq");
          setSelectTab(9);
          break;
        }
      case "CustomerFormList":
        {
          setCurrentTab("CustomerFormList");
          setSelectTab(10);
          break;
        }
    }

  }



  return (
    <div className="mt-4 ">
      <div className="sm:hidden sticky top-16 z-0 bg-white">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>

        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          defaultValue={props.tabs.find((tab) => tab.current).name}
          onChange={(e) => tab(e)}
        >
          {props.tabs?.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>

      <div className="hidden sm:block sticky top-16 z-20 bg-white">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {props.tabs.map((tab, index) => (
              <a
                key={tab.name}
                href="#"
                onClick={() => { setSelectTab(index); changeTab(tab.name) }}
                className={classNames(
                  selectTab === index
                    ? "border-indigo-500 text-indigo-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                  "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={tab.current ? "page" : undefined}
              >
                {tab.name}
                {tab.count ? (
                  <span
                    className={classNames(
                      tab.current
                        ? "bg-indigo-100 text-indigo-600"
                        : "bg-gray-100 text-gray-900",
                      "hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block "
                    )}
                  >
                    {tab.count}
                  </span>
                ) : null}
              </a>
            ))}
          </nav>
        </div>
      </div>
      {currentTab == 'Signup' ? <SignupData selectedTab='Signup' /> : ''}
      {currentTab == 'Login' ? <LoginData selectedTab='Login' /> : ''}
      {currentTab == 'Free Trial' ? <FreeTrail selectedTab='Free Trial' /> : ''}
      {currentTab == 'Subscription' ? <Subscription selectedTab='Subscription' /> : ''}
      {currentTab == 'Payment Attempt' ? < PaymentAttempt selectedTab='Payment Attempt' /> : ''}
      {currentTab == 'Career' ? <Career selectedTab='Career' /> : ''}
      {/* {currentTab == 'Request A Demo' ? <RequestADemo selectedTab = 'Request A Demo' /> : ''} */}
      {currentTab == 'Sales Details' ? <SalesExecutive selectedTab='Sales Details' /> : ''}
      {currentTab == 'Onboarding' ? <PaymentDetails selectedTab='Onboarding' /> : ''}
      {currentTab == 'Faq' ? <Faq selectedTab='Faq' /> : ''}
      {currentTab == 'CustomerFormList' ? <CustomerFormList selectedTab='CustomerFormList' /> : ''}

    </div>
  );
};

export default DashboardTab;
