/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useLocation, useNavigate } from 'react-router-dom'
import Modal from './Modal'



function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Header = () => {

  const [openModal, setOpenModal] = useState(false)


  const navigate = useNavigate()
  const { pathname } = useLocation();
  const UserProfile = localStorage.getItem("imageUrl")

  const navigation = [
    { name: "Total_SasOne_Data", href: "/", current: true},
    { name: 'Activity_Log', href: '/sasonedata', current: false },
    { name: 'QuestionAnswerList', href: '/questionanswer', current: false },

    // { name: "Article Table", href: '/articleTable', current: false },
    { name: 'Directory', href: '/directory', current: false },
    { name: 'Enquiries', href: '/enquiry', current: false },
    { name: 'Onepost_User_Activity', href: '/onepost-user-activity', current: false },
     
    { name: 'Schedule_Calender', href: '/schedule-calender', current: false },
    { name: 'Error_Log', href: '/error-log', current: false },
    { name: 'Articles', href: '/articles', current: false },
    { name: 'Banner', href: '/banner-list', current: false },
    { name: 'Creative_delivery', href: '/creativedelivery', current: false },
  
  
  ]

  let signout = () => {
    localStorage.clear()
    sessionStorage.clear()
    navigate("/login")
  }
  return (
    <>
      <Modal open={openModal} setOpen={setOpenModal} onClose={() => setOpenModal(false)} type={"ProfileModal"} />

      <Disclosure as="nav" className="bg-gray-800 sticky top-0 z-30">
        {({ open }) => (
          <div className=''>
            <div className="mx-auto  px-2 sm:px-6 lg:px-10">

              <div className="relative flex h-16 items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">

                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-1 items-center justify-center sm:items-stretch lg:justify-start w-[80%] ">
                  <div className="flex flex-shrink-0 items-center" onClick={() => navigate("/")}>
                    <img
                      className="cursor-pointer block h-8 w-auto lg:hidden"
                      src="/assets/sasoneLogo/SASOne_whitelogo.svg"
                      alt="Your Company"
                    />
                    <img
                      className="cursor-pointer hidden h-8 w-auto lg:block"
                      src="/assets/sasoneLogo/SASOne_whitelogo.svg"
                      alt="Your Company"
                    />
                  </div>
                  <div className="hidden sm:ml-6 lg:block navigation-scrollbar ">
                    <div className="flex space-x-4">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.href === pathname ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            'px-2 py-2 rounded-md text-sm font-medium'
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>

                <div className=" flex items-center">
                  <button
                    type="button"
                    className="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button>

                  <Menu as="div" className="relative ml-3">
                    <div>
                      <Menu.Button className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-8 w-8 rounded-full"
                          src={UserProfile}
                          alt=""
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <div
                              className={classNames(active ? 'bg-gray-100 cursor-pointer' : '', 'block px-4 py-2 text-sm text-gray-700 cursor-pointer')}
                              onClick={() => setOpenModal(true)}
                            >
                              Your Profile
                            </div>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                            >
                              Settings
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <div
                              // href="#"
                              onClick={() => signout()}
                              className={classNames(active ? 'bg-gray-100 cursor-pointer' : '', 'block px-4 py-2 text-sm text-gray-700 cursor-pointer')}
                            >
                              Sign out
                            </div>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="lg:hidden">
              <div className="space-y-1 px-2 pt-2 pb-3">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.href === pathname ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                      'block px-3 py-2 rounded-md text-base font-medium'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
            </Disclosure.Panel>

          </div>
        )}
      </Disclosure>
    </>
  )
}
export default Header