import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

const TinyComponents = (props) => {

    const editorRef = useRef(null);
    
    return (
        <div className='mt-8'>
            {
                props.content === "Content" ?
                    <Editor
                        apiKey='iqzc2chs08s4j8p2r5j97xczm0z74qk7tlibltqx5rgdru86'
                        // initialValue={props.updateValue}
                        value={props.updateValue}
                        onInit={(evt, editor) => editorRef.current = editor}
                        init={{
                            height: 500,
                            menubar: true,
                            selector: 'textarea',
                            plugins: [
                                "autolink", "charmap", "preview", "searchreplace", "visualblocks", "code", "fullscreen", "insertdatetime", "media", "table", "help", "wordcount", "link", "image", "anchor", "emoticons", "media", "code", "fullscreen", "lists"
                            ],
                            toolbar: 'undo redo | formatselect | fontsize | forecolor |' +
                                'bold italic underline fullscreen | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                ' removeformat | emoticons | h1 h2 h3 h4 h5 h6 | lineheight | subscript superscript | code | media | anchor | link | image | preview',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                            placeholder: "Content",
                        }}
                        onEditorChange={(newValue, editor) => props.setDataFunc(newValue)}
                    /> : <Editor
                        apiKey='iqzc2chs08s4j8p2r5j97xczm0z74qk7tlibltqx5rgdru86'
                        onInit={(evt, editor) => editorRef.current = editor}
                        initialValue={`<p>${props.content}</p>`}
                        init={{
                            height: 200,
                            menubar: false,
                            selector: 'textarea',
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount',
                                'code', 'link', 'image'
                            ],
                            toolbar: 'undo redo | formatselect | ' +
                                'bold italic underline | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | image | link | code ',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        }}
                        onEditorChange={(newValue, editor) => props.setDataFunc(newValue)}
                    />
            }
        </div>
    )
}

export default TinyComponents