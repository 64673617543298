import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../Components/TailwindComponent/Header";

const UpdateFaq = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [question, setQuestion] = useState("");
    const [answer, setAnswer] = useState("");
    const [fid, setId] = useState("");
    const [isLive, setIsLive] = useState("");
    const [show, setShow] = useState(false);
    const [apiFailled, setApiFailled] = useState(false);
    const [alertMessage, setAlertMessage] = useState("FaQ Updated Successfully");

    useEffect(() => {
        // updatefaq();
        prefilleddetails();
    }, []);

    const { id } = useParams();

    const prefilleddetails = () => {
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Token 88927950e8858bb239c62d8dcb81292630996841"
        );
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            ForumQuestionUUID: id,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch("https://api.sasone.in/apiadmin/v1/QuestionDataByID", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status_code === 200 && result.status === "Success") {
                    console.log(result.data.Name, "][][][][]");
                    setName(result.data.Name);
                    setEmail(result.data.Email);
                    setQuestion(result.data.Question);
                    setAnswer(result.data.Answer);
                } else {
                }
            })
            .catch((error) => console.log("error", error));
    };

    const updatefaq = () => {
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Token 88927950e8858bb239c62d8dcb81292630996841"
        );
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            ForumQuestionUUID: id,
            Name: name,
            Question: question,
            Answer: answer,
            Email: email,
            Status: isLive ? "1" : "0",
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch("https://api.sasone.in/apiadmin/v1/updatefaqdetails", requestOptions)
            .then((result) => {
                if (result.status_code === 200 && result.status === "Success") {
                    setName("");
                    setEmail("");
                    setQuestion("");
                    setAnswer("");
                } else {
                }
            })
            .catch((error) => console.log("error", error));
    };

    const HandleSubmitForm = (e) => {
        e.preventDefault();
        clearfield()
        // prefilleddetails();
        updatefaq();
    };

    const clearfield = () => {
        setName("");
        setEmail("");
        setQuestion("");
        setAnswer("");
    };

    return (
        <div>
            <Header />
            {/* <SuccessMessage
                show={show}
                apiFailled={apiFailled}
                message={alertMessage}
                onClose={() => {
                    setShow(false);
                }}
            /> */}
            <div className="mt-8 px-[4%] lg:px-[7%]">
                <div className="text-xl font-semibold">
                    Add <span className="text-indigo-600">Question & Answer</span>{" "}
                </div>
                <div className="flex items-center justify-between my-5 border rounded-md bg-zinc-100 p-2.5">
                    <div className="flex items-center">
                        <div className="mr-3 text-base">
                            <label htmlFor="isLive" className="font-medium text-gray-700">
                                Live:{" "}
                            </label>
                        </div>
                        <div className="flex h-5 items-center">
                            <input
                                id="isLive"
                                name="live"
                                type="checkbox"
                                className="h-5 w-5 rounded border-gray-300 text-indigo-600 mr-10 focus:ring-indigo-500"
                                checked={isLive}
                                onChange={(e) => setIsLive(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <form
                    onSubmit={HandleSubmitForm}
                    className="mt-8 grid grid-cols-6 gap-6 border p-10 rounded-lg shadow-lg border-gray-300"
                >
                    <div className="col-span-6 sm:col-span-3">
                        <label
                            for="FirstName"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Name
                        </label>

                        <input
                            type="text"
                            placeholder="Name"
                            value={name}
                            required
                            id="FirstName"
                            name="first_name"
                            className="mt-1 w-full rounded-md border-gray-300 bg-white text-sm text-gray-700 shadow-sm"
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                        <label
                            for="email"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Email
                        </label>

                        <input
                            value={email}
                            type="email"
                            required
                            placeholder="abc@email.com"
                            id="Email"
                            name="email"
                            className="mt-1 w-full rounded-md border-gray-300 bg-white text-sm text-gray-700 shadow-sm"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>

                    <div className="col-span-6">
                        <label
                            for="text"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Question
                        </label>

                        <textarea
                            id="comment"
                            value={question}
                            name="comment"
                            required
                            type="text"
                            className=" mt-1  block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500  text-sm"
                            placeholder="Fill a question"
                            onChange={(e) => setQuestion(e.target.value)}
                        />
                    </div>

                    <div className="col-span-6  ">
                        <label
                            htmlFor="Password"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Answer
                        </label>

                        <textarea
                            id="comment"
                            value={answer}
                            name="comment"
                            required
                            type="text"
                            className="mt-1  block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500  text-sm"
                            placeholder="Fill an answer"
                            onChange={(e) => setAnswer(e.target.value)}
                        />
                    </div>

                    <div className="col-span-6 sm:flex sm:items-center sm:gap-4">
                        <button
                            type="submit"
                            className="inline-block shrink-0 rounded-md border border-indigo-600 bg-indigo-500 px-8 hover:bg-indigo-400 py-2 text-sm font-medium text-white transition    focus:outline-none focus:ring "
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default UpdateFaq;
