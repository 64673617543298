import React from "react";

const DataNotFound = () => {
  return (
    <div className="py-8">
      {/* <div
        className="w-150 h-[150px] bg-no-repeat bg-center bg-contain m-auto  bg-white "
        style={{
        //   backgroundImage: "url(/assets/dataNotFoundFolder.webp)",
        }}
      ></div> */}
      <div className="w-5/6 text-center bg-white font-bold text-gray-800 text-xl pt-4  m-auto">
        No Results found
      </div>
      <div className=" w-5/6 text-center text-md bg-white   m-auto">
        No Data is available for this combination of filters
      </div>
    </div>
  );
};

export default DataNotFound;
