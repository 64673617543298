import React, { useState } from 'react'
import Header from '../Components/TailwindComponent/Header'
import BtnLoading from '../Components/TailwindComponent/BtnLoading';
import SuccessModal from '../Components/TailwindComponent/SuccessModal';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useParams } from "react-router-dom";

const UpdateQuestionAnswer = () => {

    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [question, setQuestion] = useState("")
    const [answer, setAnswer] = useState("")
    const [successMsg, setSuccessMsg] = useState(false)
    const [isLive ,setIsLive] = useState(false)
    const [questionUUID ,setQuestionUUID] =useState("")

    const navigate = useNavigate()

    useEffect(() => {
        GetQuestionDetail(id)
    }, [])

    const GetQuestionDetail = (questionUUID) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 88927950e8858bb239c62d8dcb81292630996841");

        var formdata = new FormData();
        formdata.append("QuestionUUID", questionUUID);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch("https://api.sasone.in/apiadmin/v1/QueAnsDetail", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status_code === 200 && result.status.toLowerCase() === "success") {
                    setQuestion(result.data.Question)
                    setAnswer(result.data.Answer)
                    setQuestionUUID(result.data.QuestionUUID)
                    setIsLive(result.data.IsLive==="1"?true:false)
                } else {
                    alert("Data Not Submitted")
                }
            })
            .catch(error => console.log('error', error));
    }

    const SubmitForm = () => {
        setIsLoading(true)
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Token 88927950e8858bb239c62d8dcb81292630996841"
        );
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({

            "Question": question,
            "Answer": answer,
            "IsLive" :isLive ? "1":"0",
            "QuestionUUID":questionUUID
        });


        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch("https://api.sasone.in/apiadmin/v1/UpdateQuestionsandAnswer", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status_code === 200 && result.status.toLowerCase() === "success") {
                    setIsLoading(false)
                    setSuccessMsg(true)



                } else {
                    setIsLoading(false)
                    alert("Data Not Submitted")
                }
            })
            .catch(error => console.log('error', error));
    }


    return (
        <>

            <Header />
            {successMsg && <SuccessModal Alert={"success"} onClick={() => { setSuccessMsg(false); setQuestion(""); setAnswer(""); navigate("/questionanswer") }} text={"Question and Answer added successfully!!"} />}
            <div className="px-5 sm:px-8">

                <div className=" mx-auto max-w-7xl py-8 px-4 sm:py-14 sm:px-6 lg:px-12">
                    <div className="relative border p-6 sm:p-10">
                        <form onSubmit={(e) => { e.preventDefault(); SubmitForm() }}>
                            <div className="">


                                <div className="border-b border-gray-900/10 pb-12">
                                    <h2 className="text-xl font-semibold leading-7 text-gray-900">Add a Question</h2>
                                    <p className="mt-1 text-sm leading-6 text-gray-600"></p>

                                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                                        <div className="col-span-full">
                                            <label htmlFor="street-address" className="block text-base font-medium text-gray-900">
                                                Question{" "}<span className='text-xs font-medium'>(Content limit:255)</span>
                                            </label>
                                            <div className="mt-1">
                                                <textarea
                                                    type="text"
                                                    name="message"
                                                    id="message"
                                                    value={question}
                                                    maxLength={255}
                                                    onChange={(e) => setQuestion(e.target.value)}
                                                    autoComplete="message"
                                                    required
                                                    className="block w-full  rounded-md border-gray-300 py-2 px-4 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                                                />
                                            </div>
                                        </div>


                                        <div className="col-span-full">
                                            <label htmlFor="street-address" className="block text-base font-medium text-gray-900">
                                                Answer{" "}<span className='text-xs font-medium'>(Content limit:500)</span>
                                            </label>
                                            <div className="mt-1">
                                                <textarea
                                                    type="text"
                                                    name="message"
                                                    id="message"
                                                    value={answer}
                                                    maxLength={500}
                                                    onChange={(e) => setAnswer(e.target.value)}
                                                    rows={5}
                                                    required
                                                    autoComplete="message"
                                                    className="block w-full  rounded-md border-gray-300 py-2 px-4 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-span-full">
                                            <label htmlFor="street-address" className="block text-base font-medium text-gray-900">
                                                Answer{" "}<span className='text-xs font-medium'>(Content limit:500)</span>
                                            </label>
                                            <div className="mt-1 flex items-center">
                                                <input
                                                    type="checkbox"
                                                    checked={isLive}
                                                    onChange={(e) => setIsLive(!isLive) }
                                                    className="block w-[15px] h-[20px]  rounded-md border-gray-300 p-2 text-indigo-500 shadow-sm focus:border-indigo-500 focus:ring-transparent"
                                                />
                                                <span className='pl-3 text-base font-medium'>Live</span>
                                            </div>
                                        </div>


                                    </div>
                                </div>


                            </div>

                            <div className="mt-6 flex items-center justify-end gap-x-6">
                                <a href="/questionanswer">
                                    <button

                                        type="button"
                                        className="ml-3 inline-flex justify-center rounded border  border-indigo-500 py-2 px-10 text-sm font-medium text-indigo-500 shadow-sm hover:text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    >
                                        Cancel
                                    </button>
                                </a>
                                {!isLoading ? (
                                    <button
                                        type="submit"
                                        className="ml-3 inline-flex justify-center rounded border border-transparent bg-indigo-500 py-2 px-10 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    >
                                        Save
                                    </button>
                                ) : (
                                    <BtnLoading />
                                )}
                            </div>
                        </form>

                    </div>
                </div>
            </div>

        </>
    )
}

export default UpdateQuestionAnswer