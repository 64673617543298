import React, { useState, useEffect } from "react";
import DatePicker from "../Components/TailwindComponent/DatePicker";
import List from "../Components/TailwindComponent/List";
import Searchbar from "../Components/TailwindComponent/Searchbar";
import Pagination from "../Components/TailwindComponent/Pagination";
import DataNotFound from "../Components/TailwindComponent/DataNotFound";
import ExcelDownload from "../Components/ExcelDownload";
import Header from "../Components/TailwindComponent/Header";
import { useNavigate } from "react-router-dom";

const CustomerFormList = (props) => {
    const [data, setData] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [maxPage, setMaxPage] = useState(1);
    const [searchPage, setSearchPage] = useState(1);
    const [count, setCount] = useState("");
    const [page, setPage] = useState(1);
    const navigate = useNavigate();

    useEffect(() => {
        var date = new Date();
        date.setDate(date.getDate() - 30);

        let startDateFormat =
            date.getDate().toString().length === 1
                ? "0" + date.getDate()
                : date.getDate();

        const monthFormat =
            date.getMonth() + 1 > 9
                ? date.getMonth() + 1
                : "0" + (date.getMonth() + 1);

        var finalDate =
            date.getFullYear() + "-" + monthFormat + "-" + startDateFormat;

        setStartDate(finalDate);

        var todayDate = new Date();
        todayDate.setDate(todayDate.getDate());

        let endDateFormat =
            todayDate.getDate().toString().length === 1
                ? "0" + todayDate.getDate()
                : todayDate.getDate();

        const todayMonthFormat =
            todayDate.getMonth() + 1 > 9
                ? todayDate.getMonth() + 1
                : "0" + (todayDate.getMonth() + 1);

        var finalToday =
            todayDate.getFullYear() + "-" + todayMonthFormat + "-" + endDateFormat;
        setEndDate(finalToday);
    }, []);

    useEffect(() => {
        GetQuestionAnswerList();
    }, [searchPage, startDate, endDate, searchValue]);


    // useEffect(() => {
    //   searchValueData();
    // }, [searchPage, startDate, endDate]);

    const GetQuestionAnswerList = () => {
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Token 88927950e8858bb239c62d8dcb81292630996841"
        );

        var formdata = new FormData();
        formdata.append("page", searchPage);
        formdata.append("Search", searchValue);
        formdata.append("StartDate", startDate);
        formdata.append("EndDate", endDate);


        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
        };

        fetch(
            "https://api.sasone.in/apiadmin/v1/QuestionandAnswerList",
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                console.log(result)
                if (result.status_code === 200 && result.status === "Success") {

                    setData(result.data);
                    setCount(result.count);
                    setMaxPage(Math.ceil(result.count / 20));
                } else {
                    console.log(result.message);
                }
            })
            .catch((error) => console.log("error", error));
    };










    const pageHandler = (value) => {
        setSearchPage(value);
    };

    return (
        <>
            <Header />
            <div className="px-5 sm:px-8">
                <div className="sm:flex flex-col md:items-center items-start justify-between lg:flex-row m-auto md:w-5/6">
                    <DatePicker
                        SearchButton={() => {
                            setSearchPage(1);
                            GetQuestionAnswerList();
                        }}
                        StartValue={startDate}
                        EndValue={endDate}
                        StartonChange={(e) => setStartDate(e.target.value)}
                        EndonChange={(e) => setEndDate(e.target.value)}
                    />
                    <Searchbar
                        SearchButton={() => {
                            setSearchPage(1);
                            GetQuestionAnswerList();
                        }}
                        value={searchValue}
                        onChange={(e) => {
                            setSearchValue(e.target.value);
                        }}

                    />
                </div>
                <div className="flex items-center justify-end">
                    <button
                        type="submit"
                        className="ml-3 mr-5 h-10 border border-slate-200 px-6 py-2 bg-indigo-500 text-white rounded hover:bg-indigo-700 text-base font-semibold" onClick={() => navigate("/newquestion")}>Add Question Answer</button>
                    <ExcelDownload csvData={data} fileName="Question Answer List" Excel={"Download Excel"} />
                </div>
                {data.length > 0 ? (
                    <>
                        <List data={data} type={"Q&A"} />
                        {
                            <Pagination
                                maxPages={maxPage}
                                dataCount={count}
                                datalength={data.length}
                                hitPage={pageHandler}
                                pageNum={searchPage}
                            />
                        }
                    </>
                ) : (
                    <DataNotFound />
                )}
            </div>
        </>
    );
};
export default CustomerFormList;
