import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import SuccessMessage from '../Components/TailwindComponent/SuccessMessage';


const NewPayment = () => {

    const navigate = useNavigate();
    const [selectProduct, setSelectProduct] = useState("");
    const [showSecond, setShowSecond] = useState(false);
    const [show, setShow] = useState(false);
    const [apiFailed, setApiFaild] = useState(false);
    const [productPackage, setProductPackage] = useState("");
    const [message, setMessage] = useState("");
    const [selectSubsProduct, setSelectSubsProduct] = useState("");
    const [subsProductPackage, setSubsProductPackage] = useState("");
    const [data, setData] = useState({
        name: "",
        lastname: "",
        title: "",
        mobile: "",
        email: "",
        product: "",
        paymentValidFrom: "",
        paymentValidTo: "",
        serviceType: "",
        userToken: "",
        userId: "",
        paymentDetailId: "",
        companyId: "",
        startDate: "",
        endDate: "",
        subscriptionDate: "",
        showProductList: true,
        status: false
    })

    
    const productPackageData = [
        {
            "id": 1,
            "name": "OP1",
            "product": "Onepost",
            "prize":"(₹3,526/year)"
        },
        {
            "id": 2,
            "name": "OP2",
            "product": "Onepost",
             "prize":"(₹4,999/year)"
        },
        {
            "id": 3,
            "name": "OP3",
            "product": "Onepost",
             "prize":"(₹14,999/year)"
        },
        {
            "id": 4,
            "name": "OP4",
            "product": "Onepost",
             "prize":"(₹999/quarterly)"
        },
        {
            "id": 5,
            "name": "OPMbasic",
            "product": "Onepost",
             "prize":"(₹399/month)"
        },
        {
            "id": 6,
            "name": "OPMPremium",
            "product": "Onepost",
             "prize":"(₹599/month)"
        },
        {
            "id": 7,
            "name": "OPMGold",
            "product": "Onepost",
             "prize":"(₹1599/month)"
        },
        {
            "id": 8,
            "name": "KYC",
            "product": "KYC",
             "prize":"(₹)"
        },
        {
            "id": 9,
            "name": "Wowcher",
            "product": "Wowcher",
             "prize":"(₹)"
        }
    ]
    const productTypeData = [
        {
            "id": 1,
            "name": "OP1",
            "product": "Onepost"
        },
        {
            "id": 2,
            "name": "OP2",
            "product": "KYC"
        },
        {
            "id": 3,
            "name": "OP3",
            "product": "Promote"
        },
        {
            "id": 4,
            "name": "OP4",
            "product": "Wowcher"
        }
    ]

    const handleInput = (event) => {
        setData({ ...data, [event.target.name]: event.target.value });
    }

    const messageCloseFunc = () => {
        setTimeout(() => {
            setShow(false);
        }, 2000)
    }

    const findEmail = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 88927950e8858bb239c62d8dcb81292630996841");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "Email": data.email
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://api.sasone.in/apiadmin/v1_1/GetsmuserInfo", requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                if (result.status_code == 200 && result.status === "Success" && result.message === "Posts Found Successfully") {
                    setData({ ...data, name: result.data.FirstName, lastname: result.data.LastName, mobile: result.data.Mobile, userToken: result.data.UserToken, title: result.data.JobTitle, userId: result.UserId, companyId: result.data.CompanyId, showProductList: false });
                    setShow(true);
                    setMessage("Email Varified")
                    messageCloseFunc();
                    setApiFaild(false)
                } else {
                    setData({ ...data, name: "", lastname: "", mobile: "", userToken: "", title: "", userId: "", companyId: "", showProductList: true });
                    setShow(true);
                    messageCloseFunc();
                    setMessage("Email Not Found")
                    setApiFaild(true);
                }
            })
            .catch(error => console.log('error', error));
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        console.log(data);
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 88927950e8858bb239c62d8dcb81292630996841");

        var formdata = new FormData();
        formdata.append("ProductId", productPackage);
        formdata.append("UserToken", data.userToken);
        formdata.append("ProductName", selectProduct);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch("https://api.sasone.in/apiadmin/v1/paymentdetailsmanual", requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                if (result.status_code == 200 && result.status === "Success") {
                    setShowSecond(true);
                    setData({ ...data, showProductList: true, userId: result.data.UserId, paymentDetailId: result.data.PaymentDetailId });
                } else {
                    alert("Some Error Occurred")
                }
            })
            .catch(error => console.log('error', error));
    }

    const handleValidity = (event) => {
        // event.preventDefault();
        console.log(data);
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 88927950e8858bb239c62d8dcb81292630996841");

        var formdata = new FormData();
        formdata.append("PaymentDetailId", data.paymentDetailId);
        formdata.append("UserId", data.userId);
        formdata.append("CompanyId", data.companyId);
        formdata.append("ValidFrom", data.startDate);
        formdata.append("ValidTo", data.endDate);
        formdata.append("PricingCode", subsProductPackage);
        formdata.append("ProductName", selectSubsProduct);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch("https://api.sasone.in/apiadmin/v1/PaymentCallbackManual", requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                if (result.status_code == 200 && result.status === "Success") {
                    alert(result.message)
                } else {
                    alert("Some Error Occurred")
                }
                return result;
            })
            .catch(error => console.log('error', error));
    }

    return (
        <>
            <SuccessMessage
                show={show}
                message={message}
                apiFailled={apiFailed}
                onClose={() => setShow(false)}
            />
            <div className="mt-8 px-[4%] lg:px-[7%]">
                <form
                    onSubmit={handleSubmit}
                    className="space-y-8 divide-y divide-gray-200">
                    <div className="space-y-8 divide-y divide-gray-200">
                        <div className="rounded-md border bg-white border-gray-200 p-6">
                            <div>
                                <h3 className="text-lg font-medium leading-6 text-gray-900">
                                    Onboarding Details
                                </h3>
                            </div>
                            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">

                                <div className="sm:col-span-3">
                                    <div className="items-center">
                                        <label
                                            htmlFor={"name"}
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            Email <sup className="text-xsm text-red-500">*</sup>
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                id="email"
                                                name="email"
                                                type="email"
                                                value={data.email}
                                                onChange={handleInput}
                                                autoComplete="email"
                                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="sm:col-span-3 pt-6">
                                    <div className="items-left">
                                        <button
                                            type="button"
                                            className="ml-3 inline-flex justify-center rounded border border-transparent bg-indigo-500 py-2 px-10 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            onClick={findEmail}
                                        >
                                            Search
                                        </button>
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <div className="items-center">
                                        <label
                                            htmlFor={"name"}
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            First Name
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                type="text"
                                                name="name"
                                                id="name"
                                                value={data.name}
                                                onChange={handleInput}
                                                autoComplete="given-name"
                                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 bg-gray-100 focus:ring-indigo-500 sm:text-sm"
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <div className="items-center">
                                        <label
                                            htmlFor={"lastName"}
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            Last Name
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                type="text"
                                                name="lastName"
                                                id="lastName"
                                                value={data.lastname}
                                                onChange={handleInput}
                                                autoComplete="given-name"
                                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 bg-gray-100 focus:ring-indigo-500 sm:text-sm"
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <div className="items-center">
                                        <label
                                            htmlFor={"name"}
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            Title
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                type="text"
                                                name="title"
                                                id="title"
                                                value={data.title}
                                                onChange={handleInput}
                                                autoComplete="given-title"
                                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 bg-gray-100 focus:ring-indigo-500 sm:text-sm"
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="sm:col-span-3">
                                    <div className="items-center">
                                        <label
                                            htmlFor={"name"}
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            Mobile
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                type="tel"
                                                name="mobile"
                                                id="mobile"
                                                value={data.mobile}
                                                onChange={handleInput}
                                                autoComplete="mobile-number"
                                                placeholder="+1 (000) 000-0000"
                                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 bg-gray-100 focus:ring-indigo-500 sm:text-sm"
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <div className="items-center">
                                        <label
                                            htmlFor={"name"}
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            Product Type <sup className='text-red-500'>*</sup>
                                        </label>
                                        <div className="mt-1">
                                            <select
                                                id="service-type"
                                                name="serviceType"
                                                autoComplete="service-type"
                                                value={selectProduct}
                                                onChange={(e) => setSelectProduct(e.target.value)}
                                                className={data.showProductList ? `block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 bg-gray-100 focus:ring-indigo-500 sm:text-sm` : `block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm`}
                                                required
                                                disabled={data.showProductList}
                                            >
                                                <option value="" defaultValue disabled>Select Product</option>
                                                {
                                                    productTypeData.map((item, idx) => (
                                                        <option key={idx}>{item.product}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="sm:col-span-3">
                                    <div className="items-center">
                                        <label
                                            htmlFor={"product-package"}
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            Product Package <sup className='text-red-500'>*</sup>
                                        </label>
                                        <div className="mt-1">
                                            <select
                                                id="product-package"
                                                name="product-package"
                                                autoComplete="product-package"
                                                value={productPackage}
                                                onChange={(e) => setProductPackage(e.target.value)}
                                                className={data.showProductList ? `block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 bg-gray-100 focus:ring-indigo-500 sm:text-sm` : `block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm`}
                                                disabled={data.showProductList}
                                                required
                                            >
                                                <option value="" defaultValue disabled>Select Package</option>
                                                {
                                                    productPackageData.map((item, idx) => (
                                                        (selectProduct === item.product) &&
                                                        <option value={item.name} key={idx}>{item.name+" "+item.prize}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="pt-5">
                                <div className="flex justify-end mb-5">
                                    <button
                                        type="submit"
                                        className="ml-3 mr-5 h-10 border border-slate-200 px-6 py-2 bg-white-500 text-gray rounded hover:bg-white-700 text-base font-semibold" onClick={() => navigate("/sasonedata")}>Cancel</button>
                                    <button
                                        type="submit"
                                        className="ml-3 inline-flex justify-center rounded border border-transparent bg-indigo-500 py-2 px-10 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        disabled={showSecond}
                                    >
                                        Continue
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                {
                    showSecond &&
                    <form
                        onSubmit={handleValidity}
                        className="space-y-8 divide-y my-10 divide-gray-200">
                        <div className="space-y-8 divide-y divide-gray-200">
                            <div className="rounded-md border bg-white border-gray-200 p-6">
                                <div>
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                                        Subscription Details
                                    </h3>
                                </div>
                                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                    <div className="sm:col-span-3">
                                        <div className="items-center">
                                            <label htmlFor="start-date" className="block text-sm font-medium text-gray-700">
                                                Valid From <sup className='text-red-500'>*</sup>
                                            </label>
                                            <div className="mt-1 sm:col-span-1 flex gap-2 sm:mt-0">
                                                <div className="w-full">
                                                    <input
                                                        type="date"
                                                        name="startDate"
                                                        id="start-date"
                                                        value={data.startDate}
                                                        onChange={handleInput}
                                                        autoComplete="start-date"
                                                        required
                                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sm:col-span-3">
                                        <div className="items-center">
                                            <label htmlFor="end-data" className="block text-sm font-medium text-gray-700">
                                                Valid To <sup className='text-red-500'>*</sup>
                                            </label>
                                            <div className="mt-1 sm:col-span-1 flex gap-2 sm:mt-0">
                                                <div className="w-full">
                                                    <input
                                                        type="date"
                                                        name="endDate"
                                                        id="end-date"
                                                        value={data.endDate}
                                                        onChange={handleInput}
                                                        autoComplete="end-date"
                                                        required
                                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sm:col-span-3">
                                        <div className="items-center">
                                            <label
                                                htmlFor={"subscription-product"}
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Product Type <sup className='text-red-500'>*</sup>
                                            </label>
                                            <div className="mt-1">
                                                <select
                                                    id="subscription-product"
                                                    name="subscription-product"
                                                    autoComplete="subscription-product"
                                                    value={selectSubsProduct}
                                                    onChange={(e) => setSelectSubsProduct(e.target.value)}
                                                    required
                                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                >
                                                    <option value="" defaultValue disabled>Select Product</option>
                                                    {
                                                        productTypeData.map((item, idx) => (
                                                            <option key={idx}>{item.product}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sm:col-span-3">
                                        <div className="items-center">
                                            <label
                                                htmlFor={"subscription-package"}
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Product Package <sup className='text-red-500'>*</sup>
                                            </label>
                                            <div className="mt-1">
                                                <select
                                                    id="subscription-package"
                                                    name="subscription-package"
                                                    autoComplete="subscription-package"
                                                    value={subsProductPackage}
                                                    onChange={(e) => setSubsProductPackage(e.target.value)}
                                                    required
                                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                >
                                                    <option value="" defaultValue disabled>Select Package</option>
                                                    {
                                                        productPackageData.map((item, idx) => (
                                                            (selectSubsProduct === item.product) &&
                                                            <option value={item.name} key={idx}>{item.name+" "+item.prize}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="pt-5">
                                    <div className="flex justify-end mb-5">
                                        <button
                                            type="submit"
                                            className="ml-3 mr-5 h-10 border border-slate-200 px-6 py-2 bg-white-500 text-gray rounded hover:bg-white-700 text-base font-semibold" onClick={() => navigate("/sasonedata")}>Cancel</button>
                                        <button
                                            type="submit"
                                            className="ml-3 inline-flex justify-center rounded border border-transparent bg-indigo-500 py-2 px-10 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                }
            </div>
        </>
    )
}

export default NewPayment