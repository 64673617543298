import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SuccessMessage from "../Components/TailwindComponent/SuccessMessage";
import Header from "../Components/TailwindComponent/Header";
import SuccessModal from "../Components/TailwindComponent/SuccessModal";

const CreativeDeliveryForm = () => {
  const [data, setData] = useState("");
  const [files, setFiles] = useState("");
  const [desc, setDesc] = useState("");
  const [creativeId, setCreativeId] = useState("");
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  console.log(creativeId);
  useEffect(() => {
    CreativeRequestDetailById();
  }, []);

  const CreativeRequestDetailById = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 88927950e8858bb239c62d8dcb81292630996841"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      CreativeUUID: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://api.sasone.in/apiadmin/v2/onepost/SaveCreativeRequestDetails",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          setData(result.data);
          setCreativeId(result.data.CreativeUUID);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const SaveDeliverCreative = (e) => {
    e.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 88927950e8858bb239c62d8dcb81292630996841"
    );

    var formdata = new FormData();
    formdata.append("Images", files);
    formdata.append("CreativeUUID", creativeId);
    formdata.append("CreativeDescription", desc);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://api.sasone.in/apiadmin/v1/SaveDeliveredCreative",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.status_code === 200 &&
          result.status === "Success" &&
          result.message === "Request Saved Successfully"
        ) {
          setShow(true);
        }
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <>
      <Header />
      {show && (
        <SuccessModal
          Alert={"success"}
          onClick={() => {
            setShow(false);
            setFiles("");
            setDesc("");
            navigate("/creativedelivery");
          }}
          success={"Thank-you !!"}
          text={"Your Form submitted successfully!"}
        />
      )}
      <div className="mt-8 px-[4%] lg:px-[7%]">
        <form
          onSubmit={SaveDeliverCreative}
          className="space-y-8 divide-y divide-gray-200"
        >
          <div className="space-y-8 divide-y divide-gray-200">
            <div className="rounded-md border bg-white border-gray-200 p-6">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Creative delivery Form
                </h3>
              </div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <div className="items-center">
                    <label
                      htmlFor={"name"}
                      className="block text-sm font-medium text-gray-700"
                    >
                      Email
                    </label>
                    <div className="mt-1">
                      <input
                        disabled
                        type="email"
                        value={data.UserEmail}
                        className="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 py-2.5 px-2 bg-gray-100 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <div className="items-center">
                    <label
                      htmlFor={"product-package"}
                      className="block text-sm font-medium text-gray-700"
                    >
                      Creative Request{" "}
                      <span className="text-gray-500">(Optional)</span>
                    </label>
                    <div className="mt-1">
                      <input
                        disabled
                        type="text"
                        value={data.CreativeDescription}
                        className="block w-full py-2.5 rounded-md border border-gray-300 shadow-sm focus:border-indigo-500  px-2 bg-gray-100 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <div className="items-center">
                    <label
                      htmlFor={"lastName"}
                      className="block text-sm font-medium text-gray-700"
                    >
                      Media Upload
                    </label>
                    <div className="mt-1">
                      <input
                        required
                        type="file"
                        name="lastName"
                        id="lastName"
                       
                        onChange={(e) => setFiles(e.target.files[0])}
                        autoComplete="given-name"
                        className="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 py-2 px-2 bg-gray-100 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </div>
                {/* 
                <div className="sm:col-span-3">
                  <div className="items-center">
                    <label
                      htmlFor={"name"}
                      className="block text-sm font-medium text-gray-700"
                    >
                      Media Delivery date
                    </label>
                    <div className="mt-1">
                      <input
                        type="date"
                        name="title"
                        id="title"
                        // value={data.title}
                        // onChange={handleInput}
                        autoComplete="given-title"
                        className="block w-full rounded-md border-gray-300 shadow-sm py-2.5 focus:border-indigo-500 bg-gray-100 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </div> */}
                <div className="sm:col-span-3">
                  <div className="items-center">
                    <label
                      htmlFor={"name"}
                      className="block text-sm font-medium text-gray-700"
                    >
                      Creative description
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="mobile"
                        id="mobile"
                        value={desc}
                        onChange={(e) => setDesc(e.target.value)}
                        autoComplete="off"
                        // placeholder="+1 (000) 000-0000"
                        className="block w-full py-2.5 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 bg-gray-100 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="pt-5">
                <div className="flex justify-end mb-5">
                  <div
                    className="cursor-pointer ml-3 mr-5 h-10 border border-slate-200 px-6 py-2 bg-white-500 text-gray rounded hover:bg-white-700 text-base font-semibold"
                    onClick={() => navigate("/creativedelivery")}
                  >
                    Cancel
                  </div>
                  <button
                    type="submit"
                    className="ml-3 inline-flex justify-center rounded border border-transparent bg-indigo-500 py-2 px-10 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default CreativeDeliveryForm;
