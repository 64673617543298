import React, { useState } from "react";
import DatePicker from "../Components/TailwindComponent/DatePicker";
import Searchbar from "../Components/TailwindComponent/Searchbar";
import Pagination from "../Components/TailwindComponent/Pagination";
import DataNotFound from "../Components/TailwindComponent/DataNotFound";
import ExcelDownload from "../Components/ExcelDownload";
import List from "../Components/TailwindComponent/List";
import { useEffect } from "react";
import LoadingComp from "../Components/TailwindComponent/LoadingComp";
import Modal from "../Components/TailwindComponent/Modal";

const RequestAFeature = () => {
    const [reqList, setReqList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [maxPage, setMaxPage] = useState(1);
    const [searchPage, setSearchPage] = useState(1);
    const [count, setCount] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const [modalText, setModalText] = useState("")
    const [page, setPage] = useState(1);

    useEffect(() => {
        if (searchValue === "") {
            req_a_feature_api(startDate, endDate, page);
        }
    }, [page, searchValue === ""]);

    const req_a_feature_api = () => {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Token 88927950e8858bb239c62d8dcb81292630996841"
        );
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            StartDate: startDate,
            EndDate: endDate,
            page: page,
            Search: searchValue,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(
            "https://api.sasone.in/apiadmin/v1/FeatureRequestedList",
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.status_code === 200 && result.status === "Success") {
                    setReqList(result.data);
                    setCount(result.count);
                    setMaxPage(Math.ceil(result.count / 20));
                }
            })
            .catch((error) => console.log("error", error))
            .finally(() => setLoading(false));
    };

    const pageHandler = (value) => {
        if (searchValue === "") {
            setPage(value);
        }
        if (searchValue !== "") {
            setSearchPage(value);
        }
    };

    const callBack = (val) => {
        setOpenModal(true)
        setModalText(val)
    }


    return (
        <>
            <Modal
                type={"MessageBox"}
                open={openModal}
                setOpen={setOpenModal}
                modalText={modalText}
            />
            <div className=" border border-slate-100 p-2 sm:p-6 mb-8 rounded">
                <div className="flex justify-between  items-center text-lg font-bold ">
                    <div className="underline decoration-indigo-600 underline-offset-2">
                        Request a Feature
                    </div>
                    <ExcelDownload
                        csvData={reqList}
                        fileName="Request-a-feature"
                        Excel={"Download Excel"}
                    />
                </div>
                <div className="sm:flex flex-col md:items-center items-start justify-between lg:flex-row m-auto md:w-5/6">
                    <DatePicker
                        SearchButton={() => {
                            setSearchPage(1);
                            req_a_feature_api();
                        }}
                        StartValue={startDate}
                        EndValue={endDate}
                        StartonChange={(e) => setStartDate(e.target.value)}
                        EndonChange={(e) => setEndDate(e.target.value)}
                    />
                    <Searchbar
                        SearchButton={() => {
                            setSearchPage(1);
                            req_a_feature_api();
                        }}
                        value={searchValue}
                        onChange={(e) => {
                            setSearchValue(e.target.value);
                        }}
                    />
                </div>
                {loading ? (
                    <LoadingComp />
                ) : (
                    <>
                        {reqList.length > 0 ? (
                            <>
                                <List data={reqList} type={"req_a_feature"} callBack={callBack} />
                                {
                                    <Pagination
                                        maxPages={maxPage}
                                        dataCount={count}
                                        datalength={reqList.length}
                                        hitPage={pageHandler}
                                        pageNum={searchValue === "" ? page : searchPage}
                                    />
                                }
                            </>
                        ) : (
                            <DataNotFound />
                        )}
                    </>
                )}
            </div>
        </>
    );
};

export default RequestAFeature;
