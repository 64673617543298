import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import DataNotFound from '../Components/TailwindComponent/DataNotFound';
import Header from '../Components/TailwindComponent/Header'
import LoadingComp from '../Components/TailwindComponent/LoadingComp';
import OccasionDetailGrid from '../Components/TailwindComponent/OccasionDetailGrid'

const OccationDetail = () => {
    const [data, setData] = useState([]);
    const [loading,setLoading]=useState();
    const [occasionDate,setOccasionDate]=useState("");
    const [occasion,setOccasion]=useState("")
    const {OccasionUUID}=useParams()
    useEffect(() => {
        occasionList()
        }, [])
        const occasionList=()=>{
            setLoading(true)
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Token 88927950e8858bb239c62d8dcb81292630996841");
            myHeaders.append("Content-Type", "application/json");
            
            var raw = JSON.stringify({
              "OccasionUUID": OccasionUUID
            });
            
            var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: raw,
              redirect: 'follow'
            };
            
            fetch("https://api.sasone.in/apiadmin/v1_1/GetOccasionDataByOccasionId", requestOptions)
              .then(response => response.json())
              .then(result => {
                if(result.status_code===200&& result.status==="Success"){
                    setData(result)
                    setOccasion(result.data.OccasionName)
                    setOccasionDate(result.data.OccasionDate)

                }
              })
              .catch(error => console.log('error', error)).finally(()=>setLoading(false))
        }
  return (
    <div>
    <Header />
    
    {loading ? (
        <LoadingComp />
      ) :<> {  (data.Text?.length||data.Creatives?.length)?<div className='grid sm:grid-cols-2'>
         {data.Text?.length? <OccasionDetailGrid occasion={occasion} occasionDate={occasionDate} type={"content"} data={data?.Text} />:<></> }
          {data.Creatives?.length?<OccasionDetailGrid occasion={occasion} occasionDate={occasionDate} type={"images"} data={data?.Creatives} />:<></>}
        
        </div>:<DataNotFound/>}</>}
    </div>
  )
}

export default OccationDetail