import React from 'react'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
// import classes from "./ExcelDownload.module.css"

const ExcelDownload = ({csvData, fileName,Excel,OPActivity}) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const exportToCSV = (csvData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }
    return (
       <div className={OPActivity?'flex justify-end mb-2':'flex justify-end my-4'}> <button className={"border border-slate-200 px-6 py-2 bg-indigo-500 text-white rounded hover:bg-indigo-700 text-base font-semibold"} onClick={(e) => exportToCSV(csvData,fileName)}>{Excel}</button></div>
    )
}
export default ExcelDownload