import React from 'react'

const PostTab = (props) => {

      function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
      }

    return (
        <div>
          <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">
              Select a tab
            </label>

            <select
              id="tabs"
              name="tabs"
              className="block  w-full p-2 border border-slate-200 outline-0  border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
              defaultValue={"Today"}
              onChange={(e) => props.setTabType(e.target.value)}
            >
              {props.tabs?.map((tab) => (
                <option key={tab.name}>{tab.name} </option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block ">
            <div className="border-b border-gray-200">
              <nav className="-mb-px flex" aria-label="Tabs">
                {props.tabs?.map((tab, index) => (
                  <a
                    key={tab.name}
                    href={tab.href}
                    onClick={() => {
                      props.setTabType(tab.name);
                    }}
                    className={classNames(
                      props.tabType === tab.name
                        ? "border-indigo-500 text-indigo-600"
                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                      props.twoTabs?`w-1/2 py-4 px-1 cursor-pointer text-center border-b-2 font-medium text-sm`:`w-1/3 py-4 px-1 cursor-pointer text-center border-b-2 font-medium text-sm`
                    )}
                    aria-current={tab.current ? "page" : undefined}
                  >
                    {tab.name}{" "}
           
                  </a>
                ))}
              </nav>
            </div>
          </div>
        </div>
      );
}

export default PostTab