import React, { useState, useEffect } from 'react'
import Directory from '../Components/TailwindComponent/Directory'
import Header from '../Components/TailwindComponent/Header'
import SuccessMessage from '../Components/TailwindComponent/SuccessMessage'

const DirectoryPage = () => {
  const [directoryList, setDirectoryList] = useState([])
  const [nameSearch, setNameSearch] = useState()
  const [profile, setProfile] = useState({})
  const [userProfile, setUserProfile] = useState({})
  const [subcriptionData, setSubcriptionData] = useState([])
  const [paymentData, setPaymentData] = useState([])
  const [count, setCount] = useState()
  const [openNote, setOpenNote] = useState(false)
const [notes,setNotes] = useState("")
const [show, setShow] = useState(false);
const [apiFailled, setApiFailled] = useState(false);
const [alertMessage, setAlertMessage] = useState("");


  useEffect(() => {
    DirectoryList()
  }, [nameSearch === ""])

  // useEffect(() => {
  //   UserDetails()
  // }, [])

  const DirectoryList = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 88927950e8858bb239c62d8dcb81292630996841");


    var raw = "";

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(global.apiLink + "/apiadmin/v1/UserListDirectory", requestOptions)
      .then(response => response.json())
      .then(result => {
        setDirectoryList(result.data)
        UserDetails(result.data[0].UserId)
        setCount(result.count)
      })
      .catch(error => console.log('error', error));
  }

  const DirectoryListSearch = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 88927950e8858bb239c62d8dcb81292630996841");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "SearchTearm": nameSearch
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(global.apiLink + "/apiadmin/v1/UserSearchListDirectory", requestOptions)
      .then(response => response.json())
      .then(result => {
        setDirectoryList(result.data)
        setCount(result.count)
      })
      .catch(error => console.log('error', error));
  }

  const UserDetails = (id) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 88927950e8858bb239c62d8dcb81292630996841");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "UserId": id
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(global.apiLink + "/apiadmin/v1/UserFullDeatil", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status_code === 200 && result.status === "Success") {
          setProfile(result.userdata)
          setUserProfile(result.signupdatauser)
          setSubcriptionData(result.subcriptiondata)
          setPaymentData(result.paymentdata)
         
        }
        else {
          console.log("Something went wrong");
        }
      })
      .catch(error => console.log('error', error));
  }

  const updateProfile = (id) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 88927950e8858bb239c62d8dcb81292630996841"
    );
    var formdata = new FormData();
    formdata.append("UserId", id);
   
    formdata.append("Notes", notes);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch("https://api.sasone.in/apiadmin/v1/UserProfileUpdate", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
        
          setOpenNote(false)
          setAlertMessage(result.message);
          setShow(true);
          setApiFailled(false);
          // alert("Success")
        } else {
          setAlertMessage(result.message);
          setShow(true);
          setApiFailled(true);
          // alert("!alert")
        }
      })
      .catch((error) => console.log("error", error));
  };
  const successMessage = () => {
    setTimeout(() => {
     
      setAlertMessage("");
      
      // navigate("/");
      
    }, 5000);
    return alertMessage;
  };
  return (
    <div>
      <Header />
      {alertMessage&&
      <SuccessMessage
        show={show}
        apiFailled={apiFailled}
        message={successMessage()}
        onClose={() => {
          setShow(false);
        }}
      />}
      <Directory directory={directoryList}
        value={nameSearch}
        onChange={(e) => setNameSearch(e.target.value)}
        onClick={() => DirectoryListSearch()}
        profile={profile}
        UserDetails={UserDetails}
        subcriptiondata={subcriptionData}
        paymentData={paymentData}
        Userprofile={userProfile}
        count={count}
        openNoteText={() => setOpenNote(true)}
        openNote={openNote}
        setOpen={setOpenNote}
        onClose={() => setOpenNote(false)}
        openModal={openNote} 
        noteValue={notes}
        noteonChange={(e)=>setNotes(e.target.value)}
        noteonClick={()=>updateProfile(profile.UserId)}/>
        
    </div>
  )
}

export default DirectoryPage