import React, { useState, useEffect, useMemo } from "react";
import DatePicker from "../Components/TailwindComponent/DatePicker";
import List from "../Components/TailwindComponent/List";
import Searchbar from "../Components/TailwindComponent/Searchbar";
import Pagination from "../Components/TailwindComponent/Pagination";
import { useLocation } from "react-router-dom";
import DataNotFound from "../Components/TailwindComponent/DataNotFound";
import Modal from "../Components/TailwindComponent/Modal";
import SuccessMessage from "../Components/TailwindComponent/SuccessMessage";
import ExcelDownload from "../Components/ExcelDownload";

const FreeTrail = (props) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [error, setError] = useState("");
  const [count, setCount] = useState("");
  const [page, setPage] = useState(1);
  const [searchPage, setSearchPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [maxPageDeact, setMaxPageDeact] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const location = useLocation();
  const [changeDate, setChangeDate] = useState("");
  const [SubsId, setSubsId] = useState("");
  const [show, setShow] = useState(false);
  const [apiFailled, setApiFailled] = useState(false);
  const [alertMessage, setAlertMessage] = useState("Status Updated");
  const [tab, setTab] = useState("Active");
  const [deactiveData, setDeactiveData] = useState([]);
  const [deactivecount, setDeactiveCount] = useState("");
  const [activeData,setActiveData] = useState([])
  const [deactiveDataFull,setDeactiveDataFull] = useState([])

  const tabs = [
    { name: "Active", current: true },
    { name: "Expired", current: false },
  ];

  useMemo(() => {
    setTimeout(() => {
      setShow(false);
    }, 5000);
  }, [show]);

  useEffect(() => {
    var date = new Date();
    date.setDate(date.getDate() - 30);

    let startDateformat =
      date.getDate().toString().length === 1
        ? "0" + date.getDate()
        : date.getDate();

    const monthFormat =
      date.getMonth() + 1 > 9
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1);

    var finalDate =
      date.getFullYear() + "-" + monthFormat + "-" + startDateformat;
    setStartDate(finalDate);

    var todayDate = new Date();
    todayDate.setDate(todayDate.getDate());

    let endDateformat =
      todayDate.getDate().toString().length === 1
        ? "0" + todayDate.getDate()
        : todayDate.getDate();

    const todaymonthFormat =
      todayDate.getMonth() + 1 > 9
        ? todayDate.getMonth() + 1
        : "0" + (todayDate.getMonth() + 1);

    var finalToday =
      todayDate.getFullYear() + "-" + todaymonthFormat + "-" + endDateformat;
    setEndDate(finalToday);
    FreeTrialList(finalDate, finalToday, page);
    FreeTrialListFull(finalDate, finalToday)
  }, []);

  useEffect(() => {
    if (searchValue === "") {
      FreeTrialList(startDate, endDate,page);
    }
  }, [page,searchValue === ""]);

  useEffect(() => {
    if (searchValue !== "") {
      searchValueData();
    }
  }, [searchPage]);

  const FreeTrialListFull = (end, today) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 88927950e8858bb239c62d8dcb81292630996841"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      StartDate: end ? end : startDate,
      EndDate: today ? today : endDate,

    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(global.apiLink + "/apiadmin/v1/ProductData", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          setActiveData(result.data);
     
          setDeactiveDataFull(result.deativedata);

        } else {
          console.log(result.message);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const FreeTrialList = (end, today) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 88927950e8858bb239c62d8dcb81292630996841"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      StartDate: end ? end : startDate,
      EndDate: today ? today : endDate,
      page: page,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(global.apiLink + "/apiadmin/v1/ProductData", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          setData(result.data);
          setCount(result.count);
          setMaxPage(Math.ceil(result.count / 20));
          setMaxPageDeact(Math.ceil(result.deativecount / 20));
          setDeactiveData(result.deativedata);
          setDeactiveCount(result.deativecount);
        } else {
          console.log(result.message);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const OpenModal = (date, id) => {
    let Newdate = new Date(date);
    let dateMDY = `${Newdate.getFullYear()}-${Newdate.getMonth() + 1}-${Newdate.getDate().toString().length===1?"0"+Newdate.getDate():Newdate.getDate()}`;
    console.log(dateMDY);
    setChangeDate(dateMDY);
    setSubsId(id);
    setOpenModal(true);
  };

  const searchValueData = (end, today) => {
    setData("")
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 88927950e8858bb239c62d8dcb81292630996841"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      SearchTearm: searchValue,
      page: searchPage,
      // StartDate: end ? end : startDate,
      // EndDate: today ? today : endDate,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(global.apiLink + "/apiadmin/v1/ProductSataSearch", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          setCount(result.count);
          setData(result.data);
          setDeactiveData(result.deactivatedata );
          setDeactiveCount(result.deativecount);
          setMaxPage(Math.ceil(result.count / 20));
          setMaxPageDeact(Math.ceil(result.deativecount / 20));
        } else {
        }
      })
      .catch((error) => console.log("error", error));
  };
  if (error) return <h1>{error}</h1>;

  const pageHandler = (value) => {
    if (searchValue === "") {
      setPage(value);
    }
    if (searchValue !== "") {
      setSearchPage(value);
    }
  };

  const checkDateSearch = () => {
    if (page === 1) {
      FreeTrialList(startDate, endDate);
    }
  };

  // const checkStringSearch = () => {
  //   if (searchPage === 1) {
  //     searchValueData();
  //   }
  // };

  const ExtendFreeTrail = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 88927950e8858bb239c62d8dcb81292630996841"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      SubscriptionId: SubsId,
      ValidTo: changeDate,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(global.apiLink + "/apiadmin/v1/ExtendfreeTrail", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          setAlertMessage("Extended Successfully!");
          setApiFailled(false);
          setShow(true);
          FreeTrialList();
        } else {
          setAlertMessage("Status Failed!");
          setApiFailled(true);
          setShow(true);
        }
      })
      .catch((error) => console.log("error", error));
    setSubsId("");
    setOpenModal(false);
  };

  return (
    <>
      <Modal
        open={openModal}
        setOpen={setOpenModal}
        onClose={() => {
          setOpenModal(false);
          setSubsId("");
        }}
        type={"ExtendFreeTrialModal"}
        value={changeDate}
        id={SubsId}
        onChange={(e) => setChangeDate(e.target.value)}
        onClick={ExtendFreeTrail}
        minValid={changeDate}
      />

      <SuccessMessage
        show={show}
        apiFailled={apiFailled}
        message={alertMessage}
        onClose={() => {
          setShow(false);
        }}
      />
      <div className="sm:flex flex-col md:items-center items-start justify-between lg:flex-row m-auto md:w-5/6">
        <DatePicker
          SearchButton={() => {
            setSearchValue("");
            setPage(1);
            checkDateSearch();
            FreeTrialListFull()

          }}
          StartValue={startDate}
          EndValue={endDate}
          StartonChange={(e) => setStartDate(e.target.value)}
          EndonChange={(e) => setEndDate(e.target.value)}
        />
        <Searchbar
          SearchButton={() => {
            
            setSearchPage(1);
            // checkStringSearch();
            searchValueData();

          }}
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
        />
      </div>
    

       <ul
        class="nav nav-tabs flex flex-col md:flex-row flex-wrap list-none border-b-0 pl-0 mb-4"
        id="tabs-tabFill"
        role="tablist"
      >
        <li class="nav-item flex flex-auto text-center" role="presentation">
          {tabs.map((item) => (
            <a
              key={item.name}
              href={item.href}
              onClick={() => setTab(item.name)}
              className={`cursor-pointer nav-link w-full block font-bold text-xs leading-tight 
              uppercase border-x-0 border-t-0 border-b-2 border-transparent 
              px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 
              focus:border-transparent active 
                              ${
                                item.name === tab
                                  ? "border-pink-500 text-gray-900 font-bold"
                                  : "border-gray-200 text-gray-900 font-bold"
                              } `}
              aria-current={item.name ? "page" : undefined}
            >
              {item.name}
            </a>
          ))}
        </li>
      </ul>
      
      {tab === "Active" && (
        <>
          {data?.length > 0 ? (
            <>
              <ExcelDownload csvData={activeData} fileName="Active Free Trail data" Excel={"Active Data Excel Download"}/>
              <List
                data={data}
                type={"FreeTrail"}
                modalOpen={(e, f) => OpenModal(e, f)}
              />
              <Pagination
                maxPages={maxPage}
                dataCount={count}
                datalength={data?.length}
                hitPage={pageHandler}
                pageNum={searchValue === "" ? page : searchPage}
              />
            </>
          ) : (
            <DataNotFound />
          )}
        </>
      )}
        {tab === "Expired" && (
        <>
          {deactiveData?.length > 0 ? (
            <>
             <ExcelDownload csvData={deactiveDataFull} fileName=" Expired Free Trail data" Excel={"Expired Data Excel Download"}/>
              <List
                data={deactiveData}
                type={"FreeTrail"}
                modalOpen={(e, f) => OpenModal(e, f)}
              />
              <Pagination
                maxPages={maxPageDeact}
                dataCount={deactivecount}
                datalength={deactiveData?.length}
                hitPage={pageHandler}
                pageNum={searchValue === "" ? page : searchPage}
              />
            </>
          ) : (
            <DataNotFound />
          )}
        </>
      )}     
    </>
  );
};
export default FreeTrail;
